import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  FloatingLabel,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { RxReload } from "react-icons/rx";
import { useForm } from "react-hook-form";
import WebService from "../../../Services/WebService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const TabApiCredentials = (props: any) => {
  const { t } = useTranslation();
  const {
    register,
    setValue,
    watch,
  } = useForm();
  const [isShow, setShow] = useState(false);
  var watchvariable = watch();
  useEffect(() => {
    watchvariable.app_id = props.data.app_id;
    watchvariable.t_id = localStorage.getItem('t_id');
    watchvariable.client_id = props.data.client_id;
    watchvariable.secret_id = props.data.secret_id;
    setValue("app_id", watchvariable.app_id);
    setValue("client_id", watchvariable.client_id);
    setValue("secret_id", watchvariable.secret_id);
  }, [props]);
  const RegenerateAPI = () => {
    setShow(true)
  }
  const closeClick = () => {
    setShow(false)
    // props.onDelete(false);
  }
  const successClick = () => {
    WebService.addLoader("deleteBtnModal")
    return WebService.postAPI({
      action: `zapier/regenerate-keys`,
      body: watchvariable.app_id,
    })
      .then((res: any) => {
        WebService.removeLoader("deleteBtnModal")
        toast.success(res.message)
        setShow(false)
        props.sendData(1)
      })
      .catch((error) => {
        WebService.removeLoader("deleteBtnModal")
        return error;
      });
  }
  return (
    <>
      <Card className="border-0">
        <Form className="wmx-form-style" name="Verify" id="Verify">
          <Card.Body className="px-4">
            <div className=" mb-3 font-14">
              {" "}
              {t('Common.Use_Credentials_Send_Info_To_Customers')}
            </div>

            <Row className=" justify-content-center">
              <Col lg={12}>
                <div className="mb-3">
                  <FloatingLabel controlId="TID" label={t('Tenant ID')}>
                    <Form.Control
                      type="text"
                      {...register("t_id")}
                      placeholder="Tenant ID"
                      defaultValue={localStorage.getItem('t_id') ?? ""}
                      disabled
                    />
                  </FloatingLabel>
                </div>
              </Col>
              <Col lg={12}>
                <div className="mb-3">
                  <FloatingLabel controlId="AppID" label={t('Common.App_ID')}>
                    <Form.Control
                      type="text"
                      {...register("app_id")}
                      placeholder="App ID"
                      value={watchvariable.app_id}
                      disabled
                    />
                  </FloatingLabel>
                </div>
              </Col>
              <Col lg={12}>
                <div className="mb-3">
                  <FloatingLabel controlId="ClientID" label={t('Common.Client_ID')}>
                    <Form.Control
                      type="text"
                      {...register("client_id")}
                      placeholder="Client ID"
                      value={watchvariable.client_id}
                      disabled

                    />
                  </FloatingLabel>
                </div>
              </Col>
              <Col lg={12}>
                <div className="mb-3">
                  <InputGroup>
                    <FloatingLabel
                      controlId="ClientSecretKey"
                      label={t('Common.Client_Secret')}
                    >
                      <Form.Control
                        type="text"
                        {...register("secret_id")}
                        placeholder="Client secret"
                        value={watchvariable.secret_id}
                        disabled
                        className="border-end-0"
                      />
                    </FloatingLabel>
                    <InputGroup.Text className="bg-white border-start-0">
                      <a className=" cursor-pointer" onClick={RegenerateAPI}>
                        <RxReload />
                      </a>{" "}
                    </InputGroup.Text>
                  </InputGroup>
                </div>
              </Col>
            </Row>

          </Card.Body>
        </Form>
      </Card>
      <Modal
        show={isShow}
        {...props}
        size={"md"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton onClick={closeClick}>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.heading ?? t('Common.Regenerate_API')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p dangerouslySetInnerHTML={{ __html: props.message ?? `<p>` + t('Common.Confirmation_Msg_To_Regenerate_Api') + `</p>` }}>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeClick}>{t('Common.No')}</Button>
          <Button variant="primary" id="deleteBtnModal" onClick={successClick}>{t('Common.Yes')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default TabApiCredentials;
