import React from "react";
import { useEffect, useState } from "react";
import ApiLoader from "../Common/ApiLoader/ApiLoader";
import "./Integration.css";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Breadcrumb,
  Offcanvas,
  FloatingLabel,
  Card,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import operatorLogo from "../../assets/images/logo-myoperator.svg";
import { MdClose, MdOutlineFileDownload } from "react-icons/md";
import { Label } from "../Common/Label/Label";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import DeleteModal from "../Common/DeleteModal/DeleteModal";
import { useTranslation } from "react-i18next";
import { AccessControl } from "../Common/AccessControl/AccessControl";
import Password from "../Common/Password/Password";

const Integration = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();
  const [viewDisconnect, setViewDisconnect] = useState(false);
  const [viewPopup, setViewPopup] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const [progress, setProgress] = useState(0);
  const [tempValue, setTempValue] = useState(false);
  const showPopup = () => {
    if (localStorage.getItem("myoperator") !== "true") {
      if (access.can_write || access.can_full_access) {
        setViewPopup(true);
      } else {
        setViewPopup(false);
      }
    }
  };
  const [action, setAction] = useState({});
  const [showDelete, setshowDelete] = useState(false);
  const [access, setAccess] = useState<any>({});
  useEffect(() => {
    if (localStorage.getItem("myoperator") === "true") {
      if (access.can_full_access) {
        setViewDisconnect(true);
      }
      setViewPopup(false);
    } else {
      setViewDisconnect(false);
    }
  }, [viewDisconnect]);

  const connect = () => {
    WebService.addLoader("connect");
    return WebService.postAPI({
      action: `user-website/myopreator/connect`,
      body: connectionCred,
      isShowError: true,
    })
      .then((res: any) => {
        WebService.removeLoader("connect");
        toast.success(res.message);
        localStorage.setItem("myoperator", "true");
        setTempValue((tempValue) => !tempValue);
        closePopup();
        setViewDisconnect(true);
      })
      .catch((error) => {
        WebService.removeLoader("connect");
        return error;
      });
  };
  const closePopup = () => {
    setViewPopup(false);
    reset();
  };
  const connectionCred = {
    api_token: "",
    company_id: "",
    public_ivr_id: "",
    secret_token: "",
    x_api_key: "",
  };
  const handleChange = (value: string, type: any) => {
    if (type === "stoken") {
      connectionCred.secret_token = value;
      setValue("stoken", value);
    } else if (type === "cid") {
      setValue("companyid", value);
      connectionCred.company_id = value;
    } else if (type === "publicivr") {
      connectionCred.public_ivr_id = value;
      setValue("ivr", value);
    } else if (type === "xapikey") {
      connectionCred.x_api_key = value;
      setValue("xapikey", value);
    } else if (type === "apitoken") {
      connectionCred.api_token = value;
      setValue("apitoken", value);
    }
  };
  const disConnect = () => {
    setshowDelete(false);
    setshowDelete(true);
    setAction("user-website/myopreator/disconnect");
    localStorage.setItem("myoperator", "false");
    setViewDisconnect(false);
  };
  const deleteOperator = (isDelete: boolean) => {
    setshowDelete(false);
  };
  return (
    <>
      <ApiLoader show={showLoader} progress={progress} />
      <div className="app-body email-gatway-page">
        <section className="page-breadcums">
          <Container fluid>
            <div className="">
              <Row className="align-items-center">
                <Col md={6}>
                  <Breadcrumb>
                    <Breadcrumb.Item href="#">{t('Common.Home')}</Breadcrumb.Item>
                    <Breadcrumb.Item href="#">{t('IntegrationObj.Integrations')}</Breadcrumb.Item>
                  </Breadcrumb>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
        <Container fluid>
          <Row
            className=" justify-content-center align-items-center"
            style={{ minHeight: "calc(100vh - 185px)" }}
          >
            <Col md={3} className="text-center">
              <Row className="mb-4">
                <Col>
                  <Card className=" email-gatway-card">
                    <div onClick={showPopup}>
                      <img alt="" src={operatorLogo} className="  mx-auto logo" />
                    </div>
                    {viewDisconnect ? (
                      <div className="card-footer" onClick={disConnect}>
                        {t('Common.Disconnect')}
                      </div>
                    ) : (
                      ""
                    )}
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

      <Offcanvas
        show={viewPopup}
        onHide={closePopup}
        placement="end"
        className="righSide-modal size-md"
      >
        <Form
          className="wmx-form-style"
          name="Verify"
          id="Verify"
          onSubmit={handleSubmit(connect)}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{t('Common.Connect')}</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Row>
              <Col lg={12}>
                <div className="mb-3">
                  <Password requiredMsg={{ ...register("secrettoken", { required: true }) }}
                    id="stoken"
                    name=""
                    label={t('IntegrationObj.Secret_Token')}
                    placeholder="Secret Token"
                    method={(event: any) => {
                      handleChange(event.target.value, "stoken");
                    }}
                    onError={errors.secrettoken && (
                      <Label
                        title={t('IntegrationObj.Secret_Token_Err_Msg')}
                        modeError={true}
                      />
                    )}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="mb-3">
                  <FloatingLabel label={t('IntegrationObj.Company_Id')} className="mb-3">
                    <Form.Control
                      {...register("companyid", { required: true })}
                      type="text"
                      id="cid"
                      placeholder="Company ID"
                      autoComplete="off"
                      onChange={(event) => {
                        handleChange(event.target.value, "cid");
                      }}
                    />
                    {errors.companyid && (
                      <Label
                        title={t('IntegrationObj.Company_Id_Err_Msg')}
                        modeError={true}
                      />
                    )}
                  </FloatingLabel>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="mb-3">
                  <FloatingLabel label={t('IntegrationObj.Public_Ivr_Id')} className="mb-3">
                    <Form.Control
                      {...register("publicivr", { required: true })}
                      type="text"
                      id="ivr"
                      placeholder="Public IVR ID"
                      autoComplete="off"
                      onChange={(event) => {
                        handleChange(event.target.value, "publicivr");
                      }}
                    />
                    {errors.publicivr && (
                      <Label
                        title={t('IntegrationObj.Public_Ivr_Id_Err_Msg')}
                        modeError={true}
                      />
                    )}
                  </FloatingLabel>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="mb-3">
                  <Password requiredMsg={{ ...register("xapikey", { required: true }) }}
                    id="xapikey"
                    name=""
                    label={t('IntegrationObj.X_Api_Key')}
                    placeholder="X-API-Key"
                    method={(event: any) => {
                      handleChange(event.target.value, "xapikey");
                    }}
                    onError={errors.xapikey && (
                      <Label
                        title={t('IntegrationObj.X_Api_Key_Err_Msg')}
                        modeError={true}
                      />
                    )}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="mb-3">
                  <Password requiredMsg={{ ...register("apitoken", { required: true }) }}
                    id="apitoken"
                    name=""
                    label={t('IntegrationObj.Api_Token')}
                    placeholder="API Token"
                    method={(event: any) => {
                      handleChange(event.target.value, "apitoken");
                    }}
                    onError={errors.apitoken && (
                      <Label
                        title={t('IntegrationObj.Api_Token_Err_Msg')}
                        modeError={true}
                      />
                    )}
                  />
                </div>
              </Col>
            </Row>
          </Offcanvas.Body>
          <div className="offcanvas-footer">
            <Button className="btn btn-wmx-gray" onClick={closePopup}>
              <MdClose size={18} className="icon" /> {t('Common.Close')}
            </Button>
            <Button
              className="righSide-modal size-md"
              id="connect"
              type="submit"
              name="connect"
            >
              <MdOutlineFileDownload size={18} className="icon" /> {t('Common.Connect')}
            </Button>
          </div>
        </Form>
      </Offcanvas>

      <DeleteModal
        action={action}
        show={showDelete}
        onDelete={(deleted: boolean) => deleteOperator(deleted)}
        message={t('Common.Disconnect_Message')}
        heading={t('Common.Confirmation')}
      />
      <AccessControl page_type={"INTEGRATIONS"} count={0} type={"ACCESS"} code={"INTEGRATION"} access_type={"LIST"} onComplete={(access: any) => setAccess(access)} />
    </>
  );
};

export default Integration;
