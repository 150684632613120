import HelperService from "../Services/HelperService";
import WebService from "../Services/WebService";
export type ProtectedRouteProps = {
  authenticationPath: string;
  outlet: JSX.Element;
};

export default function ProtectedRoute({
  authenticationPath,
  outlet,
}: ProtectedRouteProps) {
  if (localStorage.getItem("access_token") != null) {
    return outlet;
  } else {
    let platform = HelperService.getAppType() !== undefined ? HelperService.getAppType() : "whatsapp-commerce";
    console.log(platform);
    // let platform =
    //   localStorage.getItem("platform") !== undefined && localStorage.getItem("platform") != null
    //     ? localStorage.getItem("platform")
    //     : "whatsapp-commerce";
    localStorage.clear();
    window.location.href =
      WebService.getLoginUrl() + "/" + platform?.toLowerCase() + "/logout";
    return outlet;
  }
}
