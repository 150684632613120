import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Container, FloatingLabel, Form, InputGroup, Row } from "react-bootstrap";
import AsyncSelect from 'react-select/async';
import WebService from "../../../../Services/WebService";
import { Label } from "../../../Common/Label/Label";
import { useForm } from "react-hook-form";
import './Add.css'
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import moment from 'moment';
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import { BsSave } from "react-icons/bs";
import WebmaxyIcon from "../../../../assets/images/webmaxy-icon.svg"
import { useTranslation } from "react-i18next";
import { AccessControl } from "../../../Common/AccessControl/AccessControl";

var abortController = new AbortController();
var isPending = false;
const AddSMSTemplates = () => {
    const { t } = useTranslation();
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
    const [tempValue, setTempValue] = useState(0);
    const [variable, setVariable] = useState(0);
    const [gatewayId, setgatewayId] = useState("");
    const [flag, setFlag] = useState(false);
    const [is_gtwayshow, setGatewayShow] = useState(true);
    const [user_sms_gateway_id, setUserSmsGatewayId] = useState('');
    const [code, setCode] = useState('');
    const [id, setId] = useState('');
    const [cancreate, setCreate] = useState(0);
    const [gatewayname, setGatewayname] = useState('');
    const [htmlCursor, setHtmlCursor] = useState<any>();
    const [message, setMessage] = useState<any>("");
    const [selectKey, setSelectKey] = useState<any>();
    const [access, setAccess] = useState<any>({});
    let params = useParams();
    useEffect(() => {
        if (params.id) {
            setId(params.id ?? "")
            getTemplate(params.id)
        }
    }, []);

    const navigate = useNavigate();
    const smsTemplate = {
        id: "",
        templateId: "",
        code: "",
        type: "",
        name: "",
        emoji: "",
        message: "",
        gatewayName: "",
        variable: 0,
        user_sms_gateway_id: user_sms_gateway_id,
        user_sms_gateway_ids: [],
    }
    const [sms_template, setTemplate] = useState(smsTemplate);
    const [selectedOptions, setSelectedOptions] = useState({ "value": "", "label": "Select gateway" });
    const onSelectChange = (selectedOptions: any) => {
        sms_template.user_sms_gateway_id = selectedOptions.value;
        sms_template.code = selectedOptions.code;
        setUserSmsGatewayId(selectedOptions.value);
        setCode(selectedOptions.code)
        setCreate(selectedOptions.can_create);
        setSelectedOptions({
            value: selectedOptions.value,
            label: selectedOptions.label,
        })
    }

    const promiseGatewayOptions = (inputValue: string) =>
        new Promise<any[]>((resolve) => {
            if (isPending) {
                return abortController.abort();
            }
            abortController = new AbortController();
            isPending = true;
            var list = WebService.getAPI({
                action: `user-sms-gateway/list?keyword=` + inputValue,
                body: null,
                signal: abortController.signal
            }).then((res: any) => {
                var array: any[];
                array = [];
                if (res.length === 1) {
                    res.forEach((element: any) => {
                        array.push({
                            "value": element.id, "label": <><img src={element.short_logo} alt="logo" width={15} className="me-1"
                                onError={(error: any) => { error.target.src = WebmaxyIcon }} />{element.name}</>,
                            "code": element.code, "can_create": element.sms_gateway.can_create
                        })
                    });
                    onSelectChange(array[0])
                    setValue("user_email_gateway", array[0])
                    setSelectKey(
                        array[0].value
                    );
                    isPending = false;
                    setGatewayShow(false);
                }
                if (res.length > 1) {
                    res.forEach((element: any) => {
                        array.push({
                            "value": element.id, "label": <><img src={element.short_logo} alt="logo" width={15} className="me-1"
                                onError={(error: any) => { error.target.src = WebmaxyIcon }} />{element.name}</>,
                            "code": element.code, "can_create": element.sms_gateway.can_create
                        })
                    });
                    isPending = false;
                    setGatewayShow(true);
                }
                return array;

            }).catch((error) => {
                isPending = false;
                return [];
            });
            resolve(list);
        });

    const handleChange = (value: string, type: any) => {
        var sms_temp = sms_template;
        if (type === "NAME") {
            sms_temp.name = value;
            setValue("TempName", value)
        }
        if (type === "MESSAGE") {
            sms_temp.message = value;
            setValue("message", value);
        }
        if (type === "TEMPLATEID" && code === "TRUSTSIGNAL") {
            sms_temp.templateId = value;
            setValue("TemplateID", value);
        } else if (type === "TEMPLATEID") {
            sms_temp.templateId = ""
            setValue("TemplateID", "");
        }
        setTemplate(sms_temp);
        return setTempValue(tempValue => tempValue + 1);
    }

    const appendVariables = (event: any) => {
        var temp = sms_template;
        var count = temp.variable;
        var message = temp.message;
        var key = "{{" + (count + 1) + "}}";
        message = htmlCursor
            ? message.slice(0, htmlCursor) +
            key +
            message.slice(htmlCursor, message.length)
            : message + " " + key;
        setMessage(message);
        setHtmlCursor(htmlCursor + key.length);
        setValue("message", message);
        temp.variable = (count + 1)
        temp.message = message;
        setTemplate(temp);
    }
    const saveTemplate = () => {
        if (sms_template.message === '' || sms_template.message === undefined) {
            setFlag(true);
            return false;
        } else {
            setFlag(false);
        }
        WebService.addLoader('saveTemp');
        if (id === '') {
            var obj = {
                user_sms_gateway_id: sms_template.user_sms_gateway_id,
                name: sms_template.name,
                message: sms_template.message,
                variable_count: sms_template.variable,
                language_id: "0305f97c-4ca4-da6d-6b81-f147e4d4d0e9",
                gateway_template_id: code === "TRUSTSIGNAL" ? sms_template.templateId : ""
            }
            return WebService.postAPI({
                action: `user-sms-template`,
                body: obj,
            }).then((res: any) => {
                toast.success(res.message);
                navigate('/sms-templates');
                WebService.removeLoader('saveTemp');
                setTemplate(smsTemplate);
                setCreate(1);
                reset();
            }).catch((error) => {
                toast.error(error.message);
                WebService.removeLoader('saveTemp');
                return [];
            });
        } else if (code !== "TRUSTSIGNAL") {
            var obj1 = {
                id: id,
                user_sms_gateway_id: sms_template.user_sms_gateway_id,
                name: sms_template.name,
                message: sms_template.message,
                variable_count: sms_template.variable,
                language_id: "0305f97c-4ca4-da6d-6b81-f147e4d4d0e9",
                gateway_template_id: code === "TRUSTSIGNAL" ? sms_template.templateId : ""
            }
            return WebService.putAPI({
                action: `user-sms-template`,
                body: obj1,
                isShowError: true
            })
                .then((res: any) => {
                    setGatewayname(res)
                    //  console.log(res)
                    toast.success(res.message);
                    navigate('/sms-templates');
                    WebService.removeLoader('saveTemp');
                })
                .catch((error) => {
                    toast.error(error.message);
                    WebService.removeLoader('saveTemp');
                    return [];
                });
        }
    }

    const handleError = () => {
        if (user_sms_gateway_id === '' || user_sms_gateway_id === undefined) {
            setFlag(true)
        }
        return false;
    }

    const getTemplate = (id: any) => {
        const obj = {
            id: id
        };
        var qurey = new URLSearchParams(obj).toString();
        return WebService.getAPI({
            action: `user-sms-template/details?` + qurey,
            body: null,
        }).then((res: any) => {
            var sms = sms_template
            sms.name = res.info.user_sms_template.name;
            sms.user_sms_gateway_id = res.info.user_sms_template.user_sms_gateway_id;
            sms.variable = res.info.variable_count;
            sms.message = res.info.message;
            sms.templateId = res.info.gateway_template_id;
            sms.gatewayName = res.info.user_sms_template.user_sms_gateway.name;
            setTemplate(sms);
            setVariable(res.info.variable_count)
            setValue("TempName", res.info.user_sms_template.name);
            setValue("message", res.info.message);
            setValue("TemplateID", res.info.gateway_template_id)
            setgatewayId(res.info.user_sms_template.user_sms_gateway.id)
            setGatewayname(res.info.user_sms_template.user_sms_gateway.name)
            var ob = {
                value: res.info.user_sms_template.user_sms_gateway.id, label: res.info.user_sms_template.user_sms_gateway.name
            };
            setSelectedOptions(ob)
            setCreate(1);
            // if (res.info.gateway_template_id !== null) {
            //     setCode("TRUSTSIGNAL")
            //     setCreate(0);
            // } else {
            //     setCode("OTHER")
            //     setCreate(1);
            // }
            setTempValue(tempValue => tempValue + 1);
        }).catch((error) => {
            return error;
        });
    }

    return (
        <>

            <div className="app-body">
                <section className='page-breadcums'>
                    <Container fluid >
                        <div className=''>
                            <Row className='align-items-center'>
                                <Col md={12}>
                                    <Breadcrumb>
                                        <Breadcrumb.Item onClick={() => navigate('/kpi-dashboard')}>{t('Common.Home')}</Breadcrumb.Item>
                                        <Breadcrumb.Item>{t('SmsCampaignList.Sms_Campaigns')}</Breadcrumb.Item>
                                        <Breadcrumb.Item onClick={() => navigate("/sms-templates")}>{t('SmsCampaignList.Sms_templates')}</Breadcrumb.Item>
                                        {
                                            id === '' ?
                                                <Breadcrumb.Item>{t('SmsCampaignList.Add_Sms_Template')}</Breadcrumb.Item> :
                                                <Breadcrumb.Item>{t('SmsCampaignList.Edit_Sms_Template')}</Breadcrumb.Item>
                                        }
                                    </Breadcrumb>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
                <Container fluid>
                    <div className="table-filtr-wrap mb-3">
                        <Form className="wmx-form-style" name="wokflow" id="wokflow" onSubmit={handleSubmit(saveTemplate, handleError)} >
                            {
                                id === '' ?
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <h2 className="table-heading">{t('SmsCampaignList.Add_Sms_Template')}</h2>
                                        </Col>
                                        <Col md={6} className="d-flex justify-content-end">
                                            <button type="button" className="btn btn-light-outline mx-3" onClick={() => navigate("/sms-templates")}>
                                                <HiOutlineArrowSmLeft size={16} /> {t('Common.Back')}{" "}
                                            </button>
                                            {
                                                user_sms_gateway_id !== "" ?
                                                    cancreate ?
                                                        // <Button
                                                        //     id="saveTemp"
                                                        //     type="submit"
                                                        //     className="btn btn-wmx mx-3"
                                                        // >
                                                        //     Save
                                                        // </Button>
                                                        <Button id="saveTemp" className="btn btn-wmx loader-btn" type="submit">
                                                            <BsSave size={18} className="me-2" /> {t('Common.Save')}{" "}
                                                        </Button>
                                                        : ""
                                                    : ""
                                            }
                                        </Col>
                                    </Row> : <Row className="mb-3">
                                        <Col md={6}>
                                            <h2 className="table-heading">{t('SmsCampaignList.Edit_Sms_Template')}</h2>
                                        </Col>
                                        <Col md={6} className="d-flex justify-content-end">
                                            <button type="button" className="btn btn-light-outline mx-3" onClick={() => navigate("/sms-templates")}>
                                                <HiOutlineArrowSmLeft size={16} /> {t('Common.Back')}{" "}
                                            </button>
                                            {/* <Button
                                                id="saveTemp"
                                                type="submit"
                                                className="btn btn-wmx mx-3"
                                            >
                                                Save
                                            </Button> */}
                                            <Button id="saveTemp" className="btn btn-wmx loader-btn" type="submit">
                                                <BsSave /> {t('Common.Save')}{" "}
                                            </Button>
                                        </Col>
                                    </Row>
                            }
                            <Row >
                                <Col md={6}>
                                    <Card className=" rounded-3 h-100">
                                        {/* <Form className="wmx-form-style" name="wokflow" id="wokflow" onSubmit={handleSubmit(saveTemplate, handleError)} > */}
                                        <Card.Body className="py-4">
                                            <div className="mb-3">
                                                {is_gtwayshow ?
                                                    <AsyncSelect
                                                        placeholder={t('Common.Select_Gateways')}
                                                        cacheOptions
                                                        defaultOptions
                                                        value={selectedOptions}
                                                        onChange={onSelectChange}
                                                        isDisabled={id === '' ? false : true}
                                                        loadOptions={promiseGatewayOptions}
                                                    /> : ""}
                                                {user_sms_gateway_id === '' && flag ?
                                                    <Label
                                                        title={t('Common.Select_Sms_Gateways_Err_Msg')}
                                                        modeError={true}
                                                    />
                                                    : ""}
                                            </div>

                                            <div className='mb-3'>
                                                <InputGroup className="mb-1">
                                                    <FloatingLabel
                                                        controlId="TemplateName"
                                                        label={t('Common.Template_Name')} >
                                                        <Form.Control  {...register("TempName", { required: true })}
                                                            type="text"
                                                            maxLength={60}
                                                            value={sms_template.name}
                                                            onChange={(event) => { handleChange(event.target.value, "NAME"); }}
                                                            placeholder="Template name" />

                                                    </FloatingLabel>
                                                    <InputGroup.Text className="font-12">{sms_template.name.length}/60</InputGroup.Text>
                                                </InputGroup>
                                                {errors.TempName && (
                                                    <Label
                                                        title={t('Common.Enter_Template_Name_Err_Msg')}
                                                        modeError={true}
                                                    />
                                                )}
                                            </div>
                                            {
                                                code === "TRUSTSIGNAL" ?
                                                    <div className='mb-3'>
                                                        <InputGroup className="mb-1">
                                                            <FloatingLabel
                                                                controlId="TemplateId"
                                                                label={t('Common.Template_Id')} >
                                                                <Form.Control {...register("TemplateID", { required: true })}
                                                                    type="text"
                                                                    name="TemplateID"
                                                                    value={sms_template.templateId}
                                                                    onChange={(event) => { handleChange(event.target.value, "TEMPLATEID"); }}
                                                                    placeholder="Template ID" />
                                                                {errors.TemplateID && (
                                                                    <Label
                                                                        title={t('Common.Enter_Template_Id_Err_Msg')}
                                                                        modeError={true}
                                                                    />
                                                                )}
                                                            </FloatingLabel>
                                                        </InputGroup>
                                                    </div> : ""
                                            }

                                            <Row>
                                                <Col md={12}>
                                                    <div className=' '>
                                                        <Form.Group className=" ">
                                                            <Form.Control  {...register("message", { required: true })}
                                                                as="textarea"
                                                                id="textarea123"
                                                                name="message"
                                                                className="textarea123"
                                                                placeholder={t('Common.Enter_Message')}
                                                                value={sms_template.message}
                                                                maxLength={250}
                                                                onChange={(event) => {
                                                                    handleChange(event.target.value, "MESSAGE");
                                                                }}
                                                                onBlur={(e) =>
                                                                    e?.target &&
                                                                    setHtmlCursor(e.target.selectionStart)
                                                                }
                                                                rows={4} />

                                                            <div>
                                                                {errors.message && (
                                                                    <Label
                                                                        title={t('Common.Template_Err_Msg')}
                                                                        modeError={true}
                                                                    />
                                                                )}
                                                            </div>


                                                            {/* {errors.message && (
                                                                <Label
                                                                    title="Please enter message"
                                                                    modeError={true}
                                                                />
                                                            )} */}

                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col md={6}>
                                                    <Form.Text className="font-12 text-end">{sms_template.message.length}/250</Form.Text>
                                                </Col>
                                                <Col md={6}>
                                                    {
                                                        code !== "TRUSTSIGNAL" ?
                                                            <div className="label-text font-14">
                                                                <a className="text-brand cursor-pointer" onClick={appendVariables}>+{t('Common.Add_Variables')}</a>
                                                            </div> : ""
                                                    }
                                                </Col>
                                                <Col md={12} className="mt-3">
                                                    {
                                                        user_sms_gateway_id !== "" ?
                                                            cancreate ? "" : <Label classNames="text-danger" title={t('SmsCampaignList.Warning_Create_Template_Msg')}></Label> : ""
                                                    }
                                                </Col>
                                            </Row>
                                            {/* <Button id="saveTemp" className="btn btn-wmx" type="submit">Save</Button> */}
                                        </Card.Body>
                                        {/* </Form> */}
                                    </Card>
                                </Col>
                                <Col md={6}>
                                    <Card className=" rounded-3 h-100">
                                        <Card.Body className="py-4">
                                            <h3 className="font-medium font-16 mb-3">{t('Common.Preview')}</h3>
                                            <hr />
                                            <Row className=" justify-content-center mb-3 g-3 position-relative">
                                                <Col md={15}>
                                                    <div className='mb-1 bg-secondary-subtle p-3 preview text-wrap-space h-100'>
                                                        {
                                                            sms_template.message
                                                        }

                                                    </div>
                                                </Col>
                                                <span className="time-class">{moment(new Date()).format("hh:mm A")}</span>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Container>
            </div>
            <AccessControl page_type={"SMS_CAMPAIGNS"} count={0} type={"ACCESS"} code={"SMS_MESSAGE_TEMPLATE"} access_type={"VIEW"} onComplete={(access: any) => setAccess(access)} />
        </>
    )
}

export default AddSMSTemplates;