import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Row, Col, Form, Button, Offcanvas, Card, OffcanvasBody } from 'react-bootstrap';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import WebService from "../../../Services/WebService";
import { toast } from "react-toastify";
import FacebookLogin, { LoginOptions } from '@greatsumini/react-facebook-login';
import { MdClose, MdOutlineFileDownload } from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";
import HelperService from "../../../Services/HelperService";
import { useTranslation } from "react-i18next";
import NoImage from "../../../assets/images/noImage.png";
const SocialChannels = (props: any) => {
    const { t } = useTranslation();
    let params = useParams();
    const navigate = useNavigate();
    const [list, setListData] = useState([]);
    const [pages, setPages] = useState<any[]>([]);
    const [loader, setLoader] = useState(false);
    const [id, setId] = useState('');
    const [channel_id, setChannelId] = useState('');
    const [type, setType] = useState('');
    const [timer, setTimer] = useState<any>("");
    const [facebookAccounts, setFacebookAccounts] = useState<any[]>([]);
    const [localFbResponse, setLocalFbResponse] = useState<any>()
    const [showInstaModal, setInstaModal] = useState(false);
    const closeInstaModal = () => setInstaModal(false);
    const [showFbAddModal, setFbAddModal] = useState(false);
    const handleFbAddModal = () => setFbAddModal(true)
    const closeFbAddModal = () => setFbAddModal(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const loginOption: LoginOptions = { auth_type: "reauthenticate", scope: "pages_manage_posts,pages_show_list,business_management,instagram_basic,instagram_content_publish,instagram_manage_insights,read_insights,pages_manage_engagement,pages_messaging,pages_manage_metadata,pages_read_user_content,instagram_manage_comments,instagram_manage_messages,pages_read_engagement" }
    let fbpermissions = "pages_manage_posts,pages_show_list,business_management,instagram_basic,instagram_content_publish,instagram_manage_insights,read_insights,pages_manage_engagement,pages_messaging,pages_manage_metadata,pages_read_user_content,instagram_manage_comments,instagram_manage_messages,pages_read_engagement";
    useEffect(() => {
        getList();
        if (localStorage.getItem("social_channel") !== undefined || localStorage.getItem("social_channel") != null) {
            var ch = localStorage.getItem("social_channel") ?? "";
            if (ch != null) {
                setTimeout(() => {
                    document.getElementById(ch)?.click()
                }, 1500);
            }
        }
        setId(props.data);
    }, []);
    const getList = () => {
        setLoader(true);
        let obj = {
            page: "1",
        };
        var qurey = new URLSearchParams(obj).toString();
        return WebService.getAPI({
            action: `social-channel/list?` + qurey,
            body: null,
        })
            .then((res: any) => {
                setListData(res.list);
                setLoader(false);
            })
            .catch((error) => {
                setLoader(false);
                return error;
            });
    };
    const connectChannel = (item: any) => {
        setType(item.code);
        if (item.code === "FACEBOOK") {
            let element: HTMLElement = document.getElementsByClassName('facebook-connect-btn')[0] as HTMLElement;
            element && element.click();
        } else
            if (item.code === "GMAIL") {
                // googleLogin();
            }
            else if (item.code === "LINKEDIN") {
                linkedInLogin()
            }
            else if (item.code === "TWITTER") {
                twitterLogin()
            }
            else if (item.code === "INSTAGRAM") {
                setInstaModal(true);
            }
    }
    const twitterLogin = () => {
        var body = {
            redirectUri: "https://" + window.location.host + "/twitter/oauth/redirect",
        }

        return WebService.postAPI({
            action: `twitter/login?app_type=` + localStorage.getItem("platform"),
            body: body,
        }).then((res: any) => {
            var url = "https://api.twitter.com/oauth/authorize?oauth_token=" + res.result.oauth_token + "&oauth_token_secret=" + res.result.oauth_token_secret + "&oauth_callback_confirmed=true";
            const windowFeatures = "left=100,top=100,width=600,height=800";
            var myWindow = window.open(url, "mozillaWindow", windowFeatures);
            var timer = setInterval(function () {
                checkTwitterWindow(myWindow, timer);
            }, 1000)
            setTimer(timer)
        }).catch((error) => {
            if (error?.response?.data?.message) { toast.error(error?.response?.data?.message); }
            return error;
        });
    }
    const checkTwitterWindow = (myWindow: any, timer1: any) => {
        if (!myWindow) {
            toast.error("It has never been opened!");
            clearInterval(timer);
            clearInterval(timer1);
        } else {
            if (myWindow.closed) {
                var twitter_oauth_token = localStorage.getItem("twitter_oauth_token");
                var twitter_oauth_verifier = localStorage.getItem("twitter_oauth_verifier");
                if (twitter_oauth_token !== undefined && twitter_oauth_verifier !== undefined) {
                    clearInterval(timer);
                    console.log(timer1);
                    clearInterval(timer1);
                    localStorage.removeItem("twitter_oauth_token");
                    localStorage.removeItem("twitter_oauth_verifier");
                    getTwitterAccess(twitter_oauth_token, twitter_oauth_verifier);
                }
            }
        }
    }
    const getTwitterAccess = (twitter_oauth_token: any, twitter_oauth_verifier: any) => {
        var rec: boolean
        if (id) { rec = true } else { rec = false }
        var body = {
            twitter_oauth_token: twitter_oauth_token,
            twitter_oauth_verifier: twitter_oauth_verifier,
            is_reconnect: rec,
        }
        return WebService.postAPI({
            action: `social-user-channel/twitter-login`,
            body: body,
        }).then((res: any) => {
            if (res.success_message !== '') {
                toast.success(res.success_message);
            }
            if (res.error_message !== '') {
                toast.error(res.error_message);
            }
            clearInterval(timer);
            var obj = {
                action: "close"
            }
            props.sendData(obj)
            navigate("/social-campaign/channels")
        })
            .catch((error) => {
                if (error?.response?.data?.message) { toast.error(error?.response?.data?.message); }
                return error;
            });
    }
    const { linkedInLogin } = useLinkedIn({
        clientId: "78llz4mt2a2o9i",
        redirectUri: "https://" + window.location.host + "/linkedin/oauth/redirect",
        // redirectUri: "http://localhost:4201/linkedin/oauth/redirect",
        scope: "r_liteprofile r_emailaddress w_member_social w_member_social rw_ads w_organization_social r_organization_social r_organization_admin rw_organization_admin",
        onSuccess: (code) => {
            if (localStorage.getItem("linkedIn_code") !== undefined) {
                getLinkedinToken(localStorage.getItem("linkedIn_code"))
            } else {
                localStorage.removeItem("linkedIn_code")
            }
        },
        onError: (error) => {
            if (localStorage.getItem("linkedIn_code") !== undefined) {
                getLinkedinToken(localStorage.getItem("linkedIn_code"))
            } else {
                localStorage.removeItem("linkedIn_code")
            }
        },
    });
    const getLinkedinToken = (code: any) => {
        const body = {
            code: code,
            redirect_url: "https://" + window.location.host + "/linkedin/oauth/redirect"
        }
        return WebService.postAPI({
            action: `social-user-channel/linkedin-login`,
            body: body,
        }).then((res: any) => {
            setChannelId(res.id);
            if (res.list.length > 0 && !id) {
                var lsit: any[] = [];
                res.list.forEach((element: any) => {
                    if (element.state !== "REVOKED") {
                        lsit.push(element)
                    }
                });
                setFacebookAccounts(lsit);
                handleFbAddModal()
            } else if (res.list.length > 0 && id) {
                res.list.forEach((element: any) => {
                    if (element.id === id) {
                        setPages(element);
                        saveFbList(element);
                    }
                });
            } else {
                toast.error("Page not found");
            }
        })
            .catch((error) => {
                localStorage.removeItem("linkedIn_code")
                setLoader(false);
                if (error?.response?.data?.message) { toast.error(error?.response?.data?.message); }
                return error;
            });
    }
    const getFacebookAccounts = (token: any, id: string, type: any) => {
        if (channel_id) {
            setChannelId(channel_id);
        }
        var body = {
            auth: token,
            type: type,
            id: id
        }
        return WebService.postAPI({
            action: `social-user-channel/facebook-login`,
            body: body,
        }).then((res: any) => {
            // if (res.list.length > 0 && !id) {
            //     setFacebookAccounts(res.list);
            //     handleFbAddModal()
            // } else if (id) {
            //     res.list.forEach((element: any) => {
            //         if (element.id === id) {
            //             setPages(element);
            //             saveFbList(element);
            //         }
            //     });
            // } else {
            //     toast.error("Page not found");
            // }

            if (res.list.length > 0) {
                setChannelId(res.id);
                setFacebookAccounts(res.list);
                handleFbAddModal()
            } else {
                toast.error("Page not found");
            }
        }).catch((error) => {
            if (error?.response?.data?.message) { toast.error(error?.response?.data?.message); }
            return error;
        });
    };
    const setSelectedPages = (item: any) => {
        var selectedPage = pages;
        if (selectedPage.indexOf(item) !== -1) {
            selectedPage.splice(selectedPage.indexOf(item), 1);
        } else {
            selectedPage.push(item);
        }
        setPages(selectedPage);
    }
    const saveFbList = (item: any) => {
        WebService.addLoader("savee");
        var obj = {}
        if (id) {
            obj = {
                auth: localFbResponse,
                page_type: type,
                id: id,
                list: pages,
                is_reconnect: true,
                account_connected_id: id
            }
        } else {
            obj = {
                auth: localFbResponse,
                page_type: type,
                id: channel_id,
                list: pages,
                is_reconnect: false
            }
        }
        return WebService.postAPI({
            action: `social-user-channel/seleced-social-page`,
            body: obj,
        }).then((res: any) => {
            if (res.success_message !== '') {
                toast.success(res.success_message);
            }
            if (res.error_message !== '') {
                toast.error(res.error_message);
            }
            props.sendData(obj);
            navigate("/social-campaign/channels");
            WebService.removeLoader("savee");
        })
            .catch((error) => {
                WebService.removeLoader("savee")
                if (error?.response?.data?.message) { toast.error(error?.response?.data?.message); }
                return error;
            });

    }
    return (
        <>
            <div>
                <Row className=" justify-content-center align-items-center">
                    <Col md={12} className="text-center">
                        <Row className="mb-4 g-3">
                            {list?.map((item: any, i: any) => {
                                return (
                                    item.code !== 'GMAIL' ?
                                        <Col key={"channels_" + i} id={item.code} md={6} onClick={(iten: any) => connectChannel(item)}>
                                            <Card className="h-100 d-flex justify-content-center cursor-pointer" id={item.social_type}>
                                                <Card.Body>
                                                    <img alt="" src={item.icon} className="mx-auto logo mb-2" />
                                                    {item.code === 'FACEBOOK' ?
                                                        <FacebookLogin style={{ display: "none" }} className="btn-outline-blue btn btn-primary btn btn-primary facebook-connect-btn"
                                                            // appId="304954642189523"
                                                            appId="913330915852032"
                                                            scope={fbpermissions}
                                                            loginOptions={loginOption}
                                                            onSuccess={(response: any) => {
                                                                setLocalFbResponse(response);
                                                                setType("FACEBOOK");
                                                                getFacebookAccounts(response?.accessToken, response?.userID, "FACEBOOK");
                                                            }}
                                                            onFail={(error) => {
                                                            }}
                                                            onProfileSuccess={(response) => {
                                                            }}
                                                        /> : ""}
                                                </Card.Body>
                                                <Card.Footer className="bg-white text-center border-top rounded-bottom-3">
                                                    {HelperService.titleCase(item.title)}
                                                </Card.Footer>
                                            </Card>
                                        </Col> : ""
                                )
                            })}
                        </Row>
                    </Col>
                </Row>

            </div>

            <Offcanvas show={showInstaModal} onHide={closeInstaModal} placement="end" className="righSide-modal size-md">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{t('SocialList.Authenticate_With_Facebook')}</Offcanvas.Title>
                </Offcanvas.Header>
                <OffcanvasBody>
                    <div>
                        <label><strong>{t('SocialList.Why_Do_Need_Authenticate_Meta')}</strong> </label>
                        <p>{t('SocialList.Connect_Instagram_Business_Account')}</p>
                        <label > <strong >{t('SocialList.What_Happens_Next')}</strong> </label>
                        {/* <p>Once you’re redirected,<span className="un-line">sign in to the Meta profile that has Admin permissions for the Meta Page connected to your Instagram account </span>
                            after this is done, your Instagram account will be authenticated with Meta.</p>
                        <p>Check out our <strong>Instagram connection guide</strong> for more.</p> */}
                        <p>{t('SocialList.Once_Redirected')}</p>
                        {/* <p>{t('SocialList.Check_Out_Our')}<strong>{t('SocialList.Insta_Connection_Guide')}</strong>{t('SocialList.For_More')}</p> */}
                    </div>
                </OffcanvasBody>
                <div className='offcanvas-footer'>
                    <Button className='btn btn-wmx-gray' onClick={closeInstaModal}><MdClose size={18} className='icon' /> {t('Common.Close')}</Button>
                    <FacebookLogin className="btn btn-outline-blue btn btn-primary btn btn-primary"
                        // appId="304954642189523"
                        appId="913330915852032"
                        scope={fbpermissions}
                        loginOptions={loginOption}
                        onSuccess={(response: any) => {
                            //  console.log('Login Success!', response);
                            setLocalFbResponse(response);
                            setType("INSTAGRAM");
                            setInstaModal(false);
                            getFacebookAccounts(response?.accessToken, response?.userID, "INSTAGRAM");
                        }}
                        onFail={(error) => {
                            //  console.log('Login Failed!', error);
                        }}
                        onProfileSuccess={(response) => {
                            //  console.log('Get Profile Success!', response);
                        }}
                    />
                </div>
            </Offcanvas >
            <Offcanvas show={showFbAddModal} onHide={closeFbAddModal} placement="end" className="righSide-modal size-md">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{HelperService.titleCase(type)} Pages</Offcanvas.Title>
                </Offcanvas.Header>
                <OffcanvasBody>
                    <div>
                        {facebookAccounts.map((item: any, index: any) => (
                            <Card className="m-1">
                                <Card.Body >
                                    <Form.Group className="itemName d-flex" controlId="checkboxchment">
                                        <Form.Check id={"check_" + index} onClick={() => setSelectedPages(item)} type="checkbox" label="" />
                                        {type !== "LINKEDIN" ?
                                            <img src={item.image} alt="logo" className="m-1" width={20} height={20} onError={(error: any) => (error.target.src = NoImage)} />
                                            : ""
                                        }
                                        <label htmlFor="" className="ml-2"> {item.name}</label>
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                        ))}
                    </div>
                </OffcanvasBody>
                <div className='offcanvas-footer'>
                    <Button className='btn btn-wmx-gray' onClick={closeFbAddModal}><MdClose size={18} className='icon' /> {t('Common.Close')}</Button>
                    <Button className='btn  btn-wmx loader-btn' id="savee" type='submit' onClick={saveFbList}><MdOutlineFileDownload size={18} className="icon" /> {t('Common.Connect')}</Button>
                </div>
            </Offcanvas>
        </>
    )
}
export default SocialChannels;
