import { useEffect, useState } from "react";
import LoadingBar from 'react-top-loading-bar';

interface PropData {
  show: any;
  progress: any;
}

const ApiLoader = (props: PropData) => {
  const [isShow, setShow] = useState(false);
  const [progress, setProgress] = useState(0)
  useEffect(() => {
    setShow(props.show)
  }, [props.show]);


  useEffect(() => {
    setProgress(props.progress)
  }, [props.progress]);


  return (
    <>
      {isShow ? <LoadingBar
        color='var(--gradient-bg, linear-gradient(52deg, #FF7526 0%, #FF0092 47.29%, #6F00FF 100%))'
        progress={progress}
        height={4}
        onLoaderFinished={() => setProgress(0)}
      /> : ""}
    </>
  );
};

export default ApiLoader;
