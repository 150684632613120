import { useState } from "react";
import { Breadcrumb, Button, Col, Container, Offcanvas, Row } from "react-bootstrap";
import WMTable from "../../../Common/WMTable/WMTable";
import { FiPlus } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Operators from "../Operators/Operators";
import WebService from "../../../../Services/WebService";
import DeleteModal from "../../../Common/DeleteModal/DeleteModal";

const ConnectedOperators = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [list, setListData] = useState<any[]>([]);
    const [totalCount, settotalCount] = useState(10);
    const [showAddModal, setAddModal] = useState(false);
    const handleAddClose = () => { setAddModal(false) }
    const [sortBy, setsortBy] = useState("");
    const [orderBy, setorderBy] = useState("");
    const [showDelete, setshowDelete] = useState(false);
    const [is_show, setShow] = useState(false);
    const [action, setAction] = useState({});
    const [headers, setHeaders] = useState([
        {
            display_name: t('Common.Name'),
            is_sorting: false,
            colum_name: "name",
            is_default_sort: false,
            type: "STRING",
            // sort_by: "DESC",
        },
        {
            display_name: t('Operator'),
            is_sorting: false,
            colum_name: "image",
            is_default_sort: false,
            type: "IMAGE",
            class: "imgIcon",
        },
        {
            "display_name": t('Common.Action'),
            "is_sorting": false,
            "colum_name": "",
            "is_default_sort": false,
            "type": "ACTION",
            show: false,
            "sort_by": "",
            "colum_prefix": "",
            "class": "action-col",
            "actions": [
                {
                    "title": t('Common.Disconnect'),
                    "ref_data": "id",
                    "type": "DISCONNECT"
                }
            ]
        }


    ]);
    const checkActionCondition = (item: any, action: any) => {
        return true
    }
    const getList = () => {
        setLoader(true);
        const obj = {
            sort_by: orderBy,
            sort_order: sortBy,
            keyword: ""
        };
        var qurey = new URLSearchParams(obj).toString();
        return WebService.getAPI({
            action: `user-voice-operator/list?` + qurey,
            body: null,
        }).then((res: any) => {
            setShow(true);
            if (res.list.length === 0) {
                setAddModal(true);
                setLoader(false)
            }
            setListData(res.list);
            setLoader(false);
        }).catch((error) => {
            setLoader(false);
            return error;
        });
    };
    const setDeleteId = (id: any) => {
        setshowDelete(true)
        var obj = {
            id: id,
        };
        var qurey = new URLSearchParams(obj).toString();
        setAction("user-voice-operator/disconnect?" + qurey);
    };

    const deleteList = (isDelete: boolean) => {
        setshowDelete(false)
        if (isDelete) {
            getList();
        }
    }
    const applyAction = (data: any) => {
        if (data.type === "DISCONNECT") {
            setDeleteId(data.ref.id);
        }
    }
    const applyChange = (data: any) => {
        getList();
    }
    const addClick = () => {
        setAddModal(true);
    }
    const sendData = (data: any) => {
        if (data === "list") {
            getList();
        }
        setAddModal(false);
    }
    return (
        <>
            <div className="app-body">
                <section className='page-breadcums'>
                    <Container fluid >
                        <div className=''>
                            {/* <Link to="about" */}
                            <Row className='align-items-center'>
                                <Col md={6}>
                                    <Breadcrumb>
                                        <Breadcrumb.Item onClick={() => navigate('/kpi-dashboard')}>{t('Common.Home')}</Breadcrumb.Item>
                                        <Breadcrumb.Item>{t('Integrations')}</Breadcrumb.Item>
                                        <Breadcrumb.Item active>{t('Connected_Operators')}</Breadcrumb.Item>
                                    </Breadcrumb>
                                </Col>

                            </Row>
                        </div>
                    </Container>
                </section>
                <Container fluid >
                    <div className='table-filtr-wrap mb-3'>
                        <Row className=" align-items-center">
                            <Col md={3}>
                                <h2 className='table-heading'>{t('Connected_Operators')}</h2>
                            </Col>
                            {/* <Col className="d-flex gap-3 justify-content-end">
                                <Button onClick={addClick} className='btn btn-wmx'> <FiPlus size={18} className='icon' />{t('Common.Connect')}</Button>
                            </Col> */}
                            <Col className="d-flex gap-3 justify-content-end">
                                {list.length === 0 && is_show ? <Button onClick={addClick} className='btn btn-wmx'> <FiPlus size={18} className='icon' />{t('Common.Connect')}</Button>
                                    : ""}
                            </Col>
                        </Row>
                    </div>
                    <WMTable action_condtion={(item: any, action: any) => checkActionCondition(item, action)} loader={loader} totalCount={totalCount} list={list} headers={headers} getAction={(data: any) => applyAction(data)} getData={(data: any) => applyChange(data)} />
                </Container>
            </div>
            {showAddModal ?
                <Offcanvas show={showAddModal} onHide={handleAddClose} placement="end" className="righSide-modal size-md">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>{t('Connect_Operators')}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className='wmx-normal-form'>
                        <Operators sendData={(data: any) => sendData(data)} />
                    </Offcanvas.Body>
                </Offcanvas> : ""}

            <DeleteModal
                action={action}
                show={showDelete}
                message={`<p>` + t('Common.Disconnect_Message') + `</p>`}
                onDelete={(deleted: boolean) => deleteList(deleted)}
            />

        </>
    )
}


export default ConnectedOperators;