import React, { useEffect, useState } from "react";
import { Container, Row, Form, Col, Button, Breadcrumb, Spinner, Offcanvas, Card, FloatingLabel, ToggleButtonGroup, ToggleButton, InputGroup } from 'react-bootstrap';
import { IoCloudDownloadSharp, IoImageOutline } from "react-icons/io5"
import { BiHeart } from "react-icons/bi"
import { useParams, Link } from "react-router-dom";
import "./creative-ai.scss";
import CompoAiGeneratedCard from "./CompoAiGeneratedCard";
import WebService from "../../../Services/WebService";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import AsyncSelect from "react-select/async";
import { useForm } from "react-hook-form";
import WebServiceNode from "../../../Services/WebServiceNode";
import TableLoader from "../../Common/TableLoader/TableLoader";
import { PiFolderLight } from "react-icons/pi";
import StepWizard from "./StepWizard";
import { RiSearch2Line } from "react-icons/ri";
import NoDataFound from "../../../assets/images/empty_item.svg";
import { useTranslation } from "react-i18next";
var abortController = new AbortController();
var isPending = false;

const AiGeneratedImages = () => {
    const { t } = useTranslation();
    const [keyword, setKeyword] = useState<any>("")
    const [bgImgList, setBgImgList] = useState<any>([])
    const [showImageSearch, setShowImageSearch] = useState(false);
    const handleCloseImageSearch = () => setShowImageSearch(false);
    const [tab, setTab] = useState("")
    const [isLogoSelected, setIsLogoSelected] = useState(false)
    const [isBgImgSelected, setIsBgImgSelected] = useState(false)
    const [tableloader, setTableLoader] = useState<any>(false)
    const [imageFile, setImageFile] = useState<File>();
    const [bgImageFile, setBgImageFile] = useState<File>();
    const [bgImagePath, setBgImagePath] = useState<any>()
    const [logoPath, setLogoPath] = useState<any>()
    const [stepNo, setStepNo] = useState<any>(1)
    const [title, setTitle] = useState<any>()
    const [subTitle, setSubTitle] = useState<any>()
    const [cta, setCta] = useState<any>()
    const [font, setFont] = useState<any>()
    const [bgImage, setBgImage] = useState<any>()
    const [selectedImage, setSelectedImage] = useState<any>()
    const [sign, setSign] = useState<any>()
    const [mainColour, setMainColour] = useState<any>()
    const [subHeadColour, setSubHeadColour] = useState<any>()
    const [descColour, setDescColour] = useState<any>()
    const [platform, setPlatform] = useState<any>()
    const { register } = useForm();
    const [showEditImageModal, setShowEditImageModal] = useState(false);
    const handleEditImageModal = () => {
        setShowEditImageModal(false);
        setStepNo(1)
    }
    const [hasMore, setHasMore] = useState(true);
    const [loader, setLoader] = useState(false)
    const [imageList, setImageList] = useState<any>([])
    const [selectColumns, setSelectColumns] = useState<string[]>([]);
    const { id } = useParams();
    const [isFav, setIsFav] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [imageId, setImageId] = useState<any>()
    let count = 0;
    let timer1: any;
    const [imageDetails, setImageDetails] = useState<any>({});


    useEffect(() => {
        setLoader(true)
    }, []);

    useEffect(() => {
        generateImageList();
    }, [isFav]);

    const handleContinue = () => {
        if (stepNo === 1) {
            if (isLogoSelected)
                uploadImage("LOGO", selectedImage)
            else
                setStepNo(2)
        }
        else if (stepNo === 2)
            setStepNo(3)
        else if (stepNo === 3) {
            if (tab === 'file' && isBgImgSelected)
                uploadBgImage("IMAGE", bgImage)
            else
                updateImage(bgImage)
        }

    }

    const handleBack = () => {
        if (stepNo === 1)
            setStepNo(0)
        else if (stepNo === 2)
            setStepNo(1)
        else if (stepNo === 3)
            setStepNo(2)
    }

    const handleParentCallback = (value: any, action: any, type: any) => {
        if (type === "EDIT") {
            setShowEditImageModal(true)
            setImageId(value.id)
            getImageDetails()
        } else {
            let selectedCards = selectColumns
            if (selectedCards.indexOf(value.image_key) !== -1) {
                selectedCards.splice(selectedCards.indexOf(value.image_key), 1);
            } else {
                selectedCards.push(value.image_key);
            }
            var columns = selectedCards.slice();
            setSelectColumns(columns);
        }

    }

    const getImageDetails = () => {
        setTableLoader(true)
        const obj = {
            id: id as any
        };
        var qurey = new URLSearchParams(obj).toString();
        return WebService.getAPI({
            action: `creative-post/details?` + qurey,
            body: null,
        })
            .then((res: any) => {
                setImageDetails(res)
                setPlatform(res.info.creative)
                setFont(res.info.font)
                setSelectedImage(res.info.logo_image)
                setLogoPath(res.info.logo_image)
                setBgImagePath(res.info.image)
                setTitle(res.info.text_1)
                setSubTitle(res.info.text_2)
                setCta(res.info.text_3)
                setBgImage(res.info.image)
                setSign(res.info.cta_icon)
                setMainColour(res.info.color_1)
                setSubHeadColour(res.info.color_2)
                setDescColour(res.info.color_3)
                setTableLoader(false)

            })
            .catch((error) => {
                setTableLoader(false)
                return error;
            });
    };


    const generateImageList = () => {
        const obj = {
            page: currentPage.toString(),
            favourite: isFav.toString(),
            creative_post_id: id as any
        }
        var qurey = new URLSearchParams(obj).toString();
        return WebService.getAPI({
            action: `creative-image/generated-image-list?` + qurey,
            body: null,
        })
            .then((res: any) => {
                if (res.list?.length === 0 && currentPage === 1) {
                    if (count < 3) {
                        if ((timer1 === null || timer1 === undefined) && count === 0) {
                            timer1 = setInterval(() => {
                                generateImageList();
                            }, 5000)
                        }
                        count++;
                    }
                    else {
                        clearInterval(timer1)
                        // alert("Please try after some time")
                    }
                } else {
                    clearInterval(timer1)
                    setLoader(false)
                    if (currentPage === 1) {
                        setImageList(res.list);
                    } else {
                        var cons = imageList
                        cons.push(...res.list);
                    }
                    if (res.list?.length === 0 || res.list?.length < 12) {
                        setHasMore(false);
                    } else {
                        setHasMore(true);
                        setCurrentPage(currentPage => currentPage + 1)
                    }
                }
            })
            .catch((error) => {
                setLoader(false)
                return error;
            });
    };


    const downloadImages = () => {
        WebService.getAPI({
            action: `creative-image/download-zip?image_keys=` + selectColumns,
            body: null,
        })
            .then((res: any) => {
                var blob = new Blob([res]);
                const fileDownloadUrl = URL.createObjectURL(blob);
                toast.success(res.message)

            })
            .catch((error) => {
                return error;
            });

    }

    const promiseFontOptions = (inputValue: string) =>
        new Promise<any[]>((resolve) => {
            if (isPending) {
                return abortController.abort();
            }
            abortController = new AbortController();
            isPending = true;
            var fontList = WebService.getAPI({
                action: `font/list?keyword=${inputValue}&page=1`,
                body: null,
                signal: abortController.signal
            })
                .then((res: any) => {
                    var array: any[];
                    array = [];
                    if (res.list.length > 0) {
                        res.list.map((element: any, index: any) => {
                            array.push({ "value": element.name, "label": element.name })
                        });
                    }
                    isPending = false
                    return array;
                })
                .catch((error) => {
                    isPending = false
                    return [];
                });

            resolve(fontList);

        });

    const uploadLogo = () => {
        setIsLogoSelected(true);
        let input = document.createElement('input');
        input.type = 'file';
        input.onchange = _ => {
            let files = Array.from(input.files as any);
            const file: any = files[0];
            const imageURL = URL.createObjectURL(file);
            setSelectedImage(imageURL)
            setImageFile(file)
        };
        input.click();
    }

    const uploadBgImageData = () => {
        setIsBgImgSelected(true);
        setTab('file');
        let input = document.createElement('input');
        input.type = 'file';
        input.onchange = _ => {
            let files = Array.from(input.files as any);
            const file: any = files[0];
            const imageURL = URL.createObjectURL(file);
            setBgImage(imageURL)
            setBgImageFile(file)


        };
        input.click();
    }

    const uploadImage = (type: any, image: any) => {
        if (!isLogoSelected) {
            setStepNo(2);
            return false;
        }
        WebService.addLoader("continue")
        const obj = {
            type: type
        }
        WebService.uploadAPI({
            action: `creative-post/upload-image`,
            body: obj,
            _file: imageFile,
            key: "image",

        })
            .then((res: any) => {
                setLogoPath(res.path)
                setStepNo(2)
                WebService.removeLoader("continue")
            })
            .catch((error) => {
                WebService.removeLoader("continue")
                return error;
            });

    }

    const uploadBgImage = (type: any, image: any) => {
        if (!isBgImgSelected) {
            return false;
        }
        WebService.addLoader("continue")
        const obj = {
            type: type,
        }
        WebService.uploadAPI({
            action: `creative-post/upload-image?`,
            body: obj,
            _file: bgImageFile,
            key: "image",

        })
            .then((res: any) => {
                setBgImagePath(res.path)
                updateImage(res.path)
                WebService.removeLoader("continue")

            })
            .catch((error) => {
                setIsBgImgSelected(false)
                WebService.removeLoader("continue")
                return error;
            });
    }


    const FontChange = (selectedOption: any) => {
        imageDetails.info.font = selectedOption.value;
    }

    const handleTitleChange = (title: any) => {
        setTitle(title)
    }
    const handleSubTitleChange = (subtitle: any) => {
        setSubTitle(subtitle)
    }
    const handleCtaChange = (cta: any) => {
        setCta(cta)
    }

    const handleBgImageSelect = (item: any) => {
        if (tab === "Image") {
            setBgImage(item.assets.preview.url)
            setBgImagePath(item.assets.preview.url)
            setIsBgImgSelected(true)

        }
        else if (tab === "Account") {
            setBgImage(item.image)
            setBgImagePath(item.image)
            setIsBgImgSelected(true)
        }

        handleCloseImageSearch()

    }

    const ImagesLibrary = (key: any) => {
        setLoader(true)
        const obj = {
            page: currentPage as any,
            keyword: key,
            creative_id: platform?.id
        }
        var qurey = new URLSearchParams(obj).toString();
        return WebService.getAPI({
            action: `creative-post/image-search?` + qurey,
            body: null,
        })
            .then((res: any) => {
                setBgImgList(res.list)
                setLoader(false)

            })
            .catch((error) => {
                setLoader(false)
                return error;
            });


    }

    const ImagesSearch = () => {
        setLoader(true)
        const obj = {
            "page": currentPage as any,
        }
        var qurey = new URLSearchParams(obj).toString();
        return WebService.getAPI({
            action: `creative-post/image-library?` + qurey,
            body: null,
        })
            .then((res: any) => {
                setBgImgList(res.list)
                setLoader(false)

            })
            .catch((error) => {
                setLoader(false)
                return error;
            });


    }

    const searchList = (e: any) => {
        if (e.which === 13) {
            setKeyword(e.target.value)
            ImagesLibrary(e.target.value)

        }
    }

    const handleShowImageSearch = (key: any) => {
        if (key === "Image") {
            setTab("Image")
            setShowImageSearch(true);
        }
        else if (key === "Account") {
            setTab("Account")
            ImagesSearch()
            setShowImageSearch(true);

        }
    }


    const updateImage = (path: any) => {
        const obj = {
            "backgroundImage": path,
            "logoImage": logoPath,
            "headline": title,
            "punchline": subTitle,
            "cta": cta,
            "color1": mainColour,
            "color2": subHeadColour,
            "color3": descColour,
            "creative_post_id": id as any,
            "resolution": platform?.width + "x" + platform?.height,
            "creative_image_id": imageId
        }

        return WebServiceNode.postAPI({
            action: `/create-image/`,
            body: obj,
        })
            .then((res: any) => {
                setShowEditImageModal(false)
                setStepNo(1)

            })
            .catch((error) => {
                return error;
            });

    }

    return (
        <>
            <div className="app-body ai-generated-card-page">
                <section className='page-breadcums'>
                    <Container fluid >
                        <div className=''>
                            <Row className='align-items-center'>
                                <Col md={12}>
                                    <Breadcrumb>
                                        <Breadcrumb.Item><Link to={"/kpi-dashboard"}>{t('Common.Home')}</Link>{" "}</Breadcrumb.Item>
                                        <Breadcrumb.Item><Link to={"/ai-creatives"}>{t('AiCreatives.Ai_Creatives')}</Link>{" "}</Breadcrumb.Item>
                                    </Breadcrumb>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
                <Container fluid>
                    <Row>
                        <Col md={6}>
                            <h1 className="page-title">{t('AiCreatives.AI_Generated_Creatives')}</h1>
                            <p className="font-14 mb-3 text-secondary">{t('AiCreatives.Download_The_Creatives_You_Like_By_Using_Your_Credits.')}</p>
                        </Col>
                        <Col md={6} className="table-filtr-wrap d-flex justify-content-end gap-2">
                            <Button className="btn-filter" onClick={() => {
                                isFav === 0 ? setIsFav(1) : setIsFav(0)
                                setCurrentPage(1)
                            }}>{t('Common.Favourite')}<BiHeart size={16} style={isFav === 0 ? { color: "" } : { color: "#c038a9" }} className="icon" /></Button>
                            <Button className="btn-wmx" onClick={downloadImages}>{t('Common.Download')}<IoCloudDownloadSharp size={16} className="ms-1" /></Button>
                        </Col>
                    </Row>
                    {
                        !loader
                            ? <InfiniteScroll
                                dataLength={imageList.length}
                                next={generateImageList}
                                hasMore={hasMore}
                                loader={<div className='d-flex justify-content-center'><Spinner animation="border" variant="primary" /></div>}
                                className="mt-4 g-3 mb-4 g-3 row-cols-xxl-5 row-cols-xl-4 row-cols-lg-4 row"
                                height={"71vh"}
                                initialScrollY={100}
                            >
                                {
                                    imageList?.map((element: any, index: any) => {
                                        return (
                                            <Col key={"imager_" + index}>
                                                <CompoAiGeneratedCard element={element} parentCallback={handleParentCallback} />
                                            </Col>
                                        );
                                    })
                                }

                            </InfiniteScroll>
                            : <>
                                <div className=" mb-0   table-wrap position-relative " style={{ minHeight: "76vh" }}>
                                    <div className='component-loader'>
                                        <div className='ailoader' ></div>
                                    </div>
                                </div >
                            </>

                    }
                </Container>


                <Offcanvas show={showEditImageModal} onHide={handleEditImageModal} placement="end" className="righSide-modal size-md">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>{t('AiCreatives.Edit_Image')}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="ai-content-page pt-0">
                        <div className="steps-wizard mb-3 pt-3">
                            <div id="flowBoxes">
                                <StepWizard stepItemClassName="right arrow-shape active" stepNo="1" stepName={t('AiCreatives.Text_&_Color')} />
                                <StepWizard stepItemClassName={stepNo > 1 ? "left right  arrow-shape active" : "left right  arrow-shape"} stepNo="2" stepName={t('Common.Content')} />
                                <StepWizard stepItemClassName={stepNo > 2 ? "left right  arrow-shape active" : "left right  arrow-shape"} stepNo="3" stepName={t('Common.Image')} />
                            </div>
                        </div>
                        {
                            !tableloader
                                ? stepNo === 1
                                    ? <div className="wmx-form-style">
                                        <div className="mb-3">
                                            <div className="mb-3">
                                                <AsyncSelect
                                                    placeholder={imageDetails?.info?.font}
                                                    cacheOptions
                                                    defaultOptions
                                                    loadOptions={promiseFontOptions}
                                                    onChange={FontChange}
                                                />
                                            </div>
                                        </div>
                                        <Row>
                                            <Col md={6}>
                                                <div onClick={uploadLogo} className="upload-drag-file py-5 h-100" style={{ minHeight: "auto" }}>
                                                    <div className="text-center">
                                                        <img alt="" src={selectedImage} width={100} />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <Card className=" rounded-3 p-4 border-secondary h-100" style={{ borderStyle: "dashed" }}>

                                                    <div className="d-flex align-items-center mb-2">
                                                        <input title="Choose your color" type="color" id="exampleColorInput" className="form-control form-control-color" value={subHeadColour}
                                                            onChange={(event) => setSubHeadColour(event.target.value)} />
                                                        <span className="font-14 ms-2">{t('AiCreatives.Title_Color')}</span>
                                                    </div>
                                                    <div className="d-flex align-items-center  mb-2">
                                                        <input title="Choose your color" type="color" id="exampleColorInput" className="form-control form-control-color" value={descColour}
                                                            onChange={(event) => setDescColour(event.target.value)} />
                                                        <span className="font-14 ms-2">{t('AiCreatives.Sub_Heading')}</span>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <input title="Choose your color" type="color" id="exampleColorInput" className="form-control form-control-color" value={mainColour}
                                                            onChange={(event) => setMainColour(event.target.value)} />
                                                        <span className="font-14 ms-2">{t('AiCreatives.Cta_Color')}</span>
                                                    </div>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>
                                    : ""

                                : <TableLoader />

                        }
                        {
                            stepNo === 2
                                ?
                                <div className="wmx-form-style">
                                    <Row>
                                        <Col lg={12}>
                                            <div className='mb-3'>
                                                <FloatingLabel
                                                    label={t('Common.Title')}
                                                    className="mb-3">
                                                    <Form.Control   {...register("Title", { required: false })} type="text"
                                                        id="Title" value={title} placeholder={imageDetails.info.text_1}
                                                        onChange={(event) => { handleTitleChange(event.target.value) }} />
                                                </FloatingLabel>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <div className='mb-3'>
                                                <FloatingLabel
                                                    label={t('Common.Sub_Title')}
                                                    className="mb-3">
                                                    <Form.Control   {...register("Subtitle", { required: false })} type="text"
                                                        id="Subtitle" value={subTitle} placeholder={imageDetails.info.text_2}
                                                        onChange={(event) => { handleSubTitleChange(event.target.value) }} />

                                                </FloatingLabel>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <div className='mb-3'>
                                                <FloatingLabel
                                                    label={t('AiCreatives.Call_To_Action')}
                                                    className="mb-3">
                                                    <Form.Control   {...register("Cta", { required: false })} type="text"
                                                        id="Cta" value={cta} placeholder={imageDetails.info.text_3}
                                                        onChange={(event) => { handleCtaChange(event.target.value) }} />

                                                </FloatingLabel>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                : ""

                        }
                        {
                            stepNo === 3
                                ?
                                <>
                                    <Row>
                                        <Col lg={12}>
                                            <Card className="">
                                                <Card.Body >
                                                    {
                                                        <div onClick={uploadBgImageData} className="upload-drag-file">
                                                            <div className="text-center">
                                                                <img alt="" src={bgImage} style={{ width: "72%" }} className="mb-2" />
                                                                <p className="font-16 font-500 mb-0">{t('AiCreatives.Upload_A_Background_Image')}</p>
                                                                <p className="font-14 font-400">{t('AiCreatives.Or_Drag_&_Drop_A_Background_Image_Here.')}</p>
                                                            </div>
                                                        </div>

                                                    }

                                                </Card.Body>
                                                <Card.Body className="pt-0">
                                                    <div className="choose-img-from-button text-center ">
                                                        <ToggleButtonGroup type="radio" name="options" defaultValue={1} >
                                                            <ToggleButton id="tbg-radio-1" value={1} onClick={() => handleShowImageSearch("Image")}>
                                                                <div className="d-flex align-items-center gap-2 justify-content-between">
                                                                    <div className="text-start font-500" >
                                                                        <p className="font-12 mb-0">{t('Common.Image')}</p>
                                                                        <p className="font-14 mb-0">{t('AiCreatives.Search_Engine')}</p>
                                                                    </div>
                                                                    <div>
                                                                        <IoImageOutline size={32} />
                                                                    </div>
                                                                </div>
                                                            </ToggleButton>
                                                            <ToggleButton id="tbg-radio-2" value={2} onClick={() => handleShowImageSearch("Account")} >
                                                                <div className="d-flex align-items-center gap-2 justify-content-between">
                                                                    <div className="text-start font-500">
                                                                        <p className="font-12 mb-0">{t('Common.Account')}</p>
                                                                        <p className="font-14 mb-0">{t('AiCreatives.Image_Library')}</p>
                                                                    </div>
                                                                    <div>
                                                                        <PiFolderLight size={32} />
                                                                    </div>
                                                                </div>
                                                            </ToggleButton>
                                                        </ToggleButtonGroup>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>

                                </>
                                : ""
                        }

                    </Offcanvas.Body>
                    <div className='offcanvas-footer'>
                        {
                            stepNo > 1
                                ? <Button className='btn btn-wmx-gray' onClick={handleBack} ><HiOutlineArrowLeft size={18} className='icon ms-1' />{t('Common.Back')}</Button>
                                : <Button className='btn btn-wmx-gray' onClick={handleEditImageModal} ><MdClose size={18} className='icon' />{t('Common.Cancel')}</Button>
                        }
                        <Button className='btn  btn-wmx loader-btn' id="continue" onClick={handleContinue} >{t('Common.Continue')}<HiOutlineArrowRight size={18} className='icon ms-1' /> </Button>
                    </div>
                </Offcanvas >


                <Offcanvas show={showImageSearch} onHide={handleCloseImageSearch} placement="end" className="righSide-modal size-md">
                    <Offcanvas.Header closeButton>
                        {
                            tab === "Image"
                                ? <Offcanvas.Title>{t('AiCreatives.Image_Search_Engine')}</Offcanvas.Title>
                                : <Offcanvas.Title>{t('AiCreatives.Image_Library')}</Offcanvas.Title>
                        }

                    </Offcanvas.Header>
                    <Offcanvas.Body className="ai-content-page">
                        {
                            tab === "Account"
                                ? ""
                                : <div className="search-header py-2 bg-white">
                                    <InputGroup className="table-search-input w-100">
                                        <InputGroup.Text>
                                            <RiSearch2Line size={18} className='text-gray' />
                                        </InputGroup.Text>
                                        <Form.Control placeholder='Search' onChange={(event) => {
                                            // setKeyword(event.target.value);
                                        }} onKeyDown={searchList} />
                                    </InputGroup>
                                </div>
                        }
                        {
                            !loader
                                ?
                                <div className="masonry-grid">
                                    <div className="flexbox">
                                        {tab === "Account"
                                            ? bgImgList?.map((item: any, index: any) => {
                                                return (
                                                    <div className={item?.url === bgImage ? "item framesize border border-brand" : "item framesize"} key={"baff_" + index}>
                                                        <img alt="" src={item.image} onClick={() => handleBgImageSelect(item)} />
                                                    </div>
                                                )

                                            })
                                            : bgImgList?.length === 0 && keyword !== ""
                                                ?
                                                (
                                                    <div className="table-wrap position-relative">
                                                        <div className="no-data-flound">
                                                            <div className="text-center">
                                                                <img alt="" src={NoDataFound} width={110} className="mb-3" />
                                                                <h3 className="text-secondary font-12">{t('Common.No_Record_Found')}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                                : bgImgList?.map((item: any, index: any) => {
                                                    return (
                                                        <div className={item?.url === bgImage ? "item framesize border border-brand" : "item framesize"} key={"bafpi_" + index}>
                                                            <img alt="" src={item?.assets?.preview?.url} onClick={() => handleBgImageSelect(item)} />
                                                        </div>
                                                    )

                                                })

                                        }
                                    </div>
                                </div>
                                : <TableLoader />
                        }


                    </Offcanvas.Body>
                </Offcanvas >


            </div >
        </>
    )
}
export default AiGeneratedImages;