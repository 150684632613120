import { useEffect, useState } from "react";
import { Row, Col, Form, Button, Offcanvas, FloatingLabel, Card } from 'react-bootstrap';
import WebService from "../../../../Services/WebService";
import { toast } from "react-toastify";
import { MdClose, MdOutlineFileDownload } from "react-icons/md";
import HelperService from "../../../../Services/HelperService";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import "./Operators.scss";
import { Label } from "../../../Common/Label/Label";
import Password from "../../../Common/Password/Password";

interface propData {
    sendData: any
}
const Operators = (props: any) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        getValues,
    } = useForm();
    const { t } = useTranslation();
    const [list, setListData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [fields, setFields] = useState([]);
    const [gatewayName, setGatewayName] = useState("");
    const [gatewayType, setgatewayType] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const handleClose = () => {
        setShowPopup(false);
        reset();
    };
    const [OperatorId, setOperatorId] = useState("");
    const handleShow = () => setShowPopup(true);
    useEffect(() => {
        getList();
    }, []);
    const getList = () => {
        setLoader(true);
        let obj = {
            page: "1",

        };
        var qurey = new URLSearchParams(obj).toString();
        return WebService.getAPI({
            action: `voice-operator/list?` + qurey,
            body: null,
        }).then((res: any) => {
            setListData(res.list);
            setLoader(false);
        }).catch((error) => {
            setLoader(false);
            return error;
        });
    };
    const drawConnectGateWayPopup = (item: any) => {
        var field = item.fields;
        setFields(field);
        setgatewayType(item.code);
        setGatewayName(item.name);
        setOperatorId(item.id);
        handleShow();
    };
    const setType = (type: any) => {
        setShowPopup(true);
    };
    const connectGateway = () => {
        setLoader(true);
        WebService.addLoader("connect");
        var obj: any = {};
        fields.forEach((element: any, idx: any) => {
            obj[element.column] = getValues(idx + "_field");
        });
        obj["voice_operator_id"] = OperatorId;
        return WebService.postAPI({
            action: `user-voice-operator/integrate`,
            body: obj,
        }).then((res: any) => {
            WebService.removeLoader("connect");
            localStorage.setItem("voice_operator", "true");
            setShowPopup(false);
            reset();
            handleClose();
            setLoader(false);
            if (res.success_message !== "") {
                toast.success(res.success_message);
                props.sendData("list")
            }
            if (res.error_message !== "") {
                toast.error(res.error_message);
            }
            props.sendData("Close")
        }).catch((error) => {
            setLoader(false);
            if (error?.response?.data?.message) { toast.error(error?.response?.data?.message); }
            setShowPopup(false);
            WebService.removeLoader("connect");
            handleShow();
            return error;
        });
    };
    return (
        <>
            <div>
                <Row className=" justify-content-center align-items-center">
                    <Col md={12} className="text-center">
                        <Row className=" justify-content-center align-items-center">
                            <Col md={16} className="text-center">
                                <Row className="mb-4 g-3">
                                    {list?.map((item: any, i: any) => {
                                        return (
                                            <Col
                                                key={"gateways_" + i}
                                                md={6}
                                                onClick={(iten: any) => drawConnectGateWayPopup(item)}
                                            >
                                                <Card className="h-100 d-flex justify-content-center cursor-pointer">
                                                    <Card.Body>
                                                        {" "}
                                                        <img
                                                            alt=""
                                                            src={item.image}
                                                            className="mx-auto logo mb-2 mxImage"
                                                        />
                                                    </Card.Body>
                                                    <Card.Footer className="bg-white text-center border-top rounded-bottom-3">
                                                        {HelperService.titleCase(item.name)}
                                                    </Card.Footer>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <Offcanvas
                show={showPopup}
                onHide={handleClose}
                placement="end"
                className="righSide-modal size-md"
            >
                <Form
                    className="wmx-form-style"
                    name="Verify"
                    id="Verify"
                    onSubmit={handleSubmit(connectGateway)}
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>{gatewayName}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Row>
                            <Col lg={12}>
                                <div className="mb-3">
                                    {fields?.map((item: any, index: any) => {
                                        return (
                                            <>
                                                {item.type === "PASSWORD" ?


                                                    <div key={"gateways_" + index} className="mb-3"><Password requiredMsg={{
                                                        ...register(index + "_field", {
                                                            required: item.required,
                                                        })
                                                    }}
                                                        inputGroupClassName=""
                                                        id=""
                                                        name=""
                                                        key={index}
                                                        label={item.name}
                                                        placeholder="Enter Password"
                                                        onError={
                                                            index + "_field" in errors ? (
                                                                <>
                                                                    <Label
                                                                        title={"Please enter " + item.name}
                                                                        modeError={true}
                                                                    />
                                                                </>
                                                            ) : (
                                                                ""
                                                            )}
                                                    /></div>
                                                    :
                                                    < FloatingLabel
                                                        key={index}
                                                        label={item.name}
                                                        className="mb-3" >
                                                        <Form.Control
                                                            {...register(index + "_field", {
                                                                required: item.required,
                                                            })}
                                                            key={index}
                                                            type={item.type}
                                                            placeholder="Enter value"
                                                        />
                                                        {
                                                            index + "_field" in errors ? (
                                                                <>
                                                                    <Label
                                                                        title={"Please enter " + item.name}
                                                                        modeError={true}
                                                                    />
                                                                </>
                                                            ) : (
                                                                ""
                                                            )
                                                        }
                                                    </FloatingLabel>}
                                            </>)
                                    })}
                                </div>
                            </Col>
                        </Row>
                    </Offcanvas.Body>
                    <div className="offcanvas-footer">
                        <Button className="btn btn-wmx-gray" onClick={handleClose}>
                            <MdClose size={18} className="icon" /> {t('Common.Close')}
                        </Button>
                        <Button className="btn-wmx loader-btn" id="connect" onClick={() => setType("CONNECT")} type="submit" >
                            <MdOutlineFileDownload size={18} className="icon" /> {t('Common.Connect')}
                        </Button>
                    </div>
                </Form>
            </Offcanvas >
        </>
    )
}
export default Operators;
