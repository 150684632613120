import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Breadcrumb,
  Offcanvas,
  FloatingLabel,
} from "react-bootstrap";
import { BiPlus } from "react-icons/bi";
import { PiTrashSimpleBold } from "react-icons/pi";
import { MdModeEditOutline, MdClose } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./help-desk.scss";
import { useForm } from "react-hook-form";
import { Label } from "../../../Common/Label/Label";
import { BsSave } from "react-icons/bs";
import AsyncSelect from "react-select/async";
import FBLogo from "../../../../assets/images/Social_Logos/facebook.svg";
import InstaLogo from "../../../../assets/images/Social_Logos/instagram.svg";
import LinkdnLogo from "../../../../assets/images/Social_Logos/linkedin.svg";
import TwitterLogo from "../../../../assets/images/Social_Logos/twitter.svg";
import whatsAppLogo from "../../../../assets/images/whatsappLogo.svg";
import gmailLogo from "../../../../assets/images/gmail-icon.svg";
import WebService from "../../../../Services/WebService";
import HelperService from "../../../../Services/HelperService";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { toast } from "react-toastify";
import WebmaxyIcon from "../../../../assets/images/webmaxy-icon.svg";
import SocialChannels from "../../../Social/SocialChannels/Channels";
import { useTranslation } from "react-i18next";
import { AccessControl } from "../../../Common/AccessControl/AccessControl";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
var abortController = new AbortController()
var isTagListPending = false
var isMemberListPending = false
var isSupportSourceListPending = false

const AddRule = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    unregister,
  } = useForm();
  const maxLength = 1500;
  const navigate = useNavigate();
  const [showEditModal, setEditModal] = useState(false);
  const [tempValue, setTempValue] = useState(false);
  const [selectedAction, setSelectedAction] = useState<any[]>([]);
  const [editorConfiguration, setEditorConfiguration] = useState<any>();
  const [editorErrorFlag, setEditorErrorFlag] = useState(false);
  const [criteria, setCriteria] = useState<any>();
  const [customerTypeArray, setCustomerTypeArray] = useState<any[]>([]);
  const [socialChannelArray, setSocialChannelArray] = useState<any[]>([]);
  const [optionErrorFlag, setOptionErrorFlag] = useState(false);
  const [allConditionOption, setAllConditionOptions] = useState<any[]>([]);
  const [indexForEditor, setIndexForEditor] = useState(0);
  const [editorData, setEditorData] = useState("");
  const [is_cus_selected, setIsCusSelected] = useState(false);
  const [access, setAccess] = useState<any>({});
  const [stateChange, setStateChange] = useState(false);
  const [showEditButton, setShowEditButton] = useState(false);
  const [show, setShow] = useState(false);
  const [is_social_channel_selected, set_social_channel_selected] =
    useState(false);

  let params = useParams();
  var watchvariable = watch();
  const [socialChannels, setSocialChannels] = useState<any[]>([]);
  useEffect(() => { }, [stateChange]);
  useEffect(() => {
    if (params.id) {
      detailCall(params.id);
    } else {
      var criteria1 = {
        action: [
          {
            name: "",
            value: "",
            editor: "",
            key: "",
          },
        ],
        action_operation: "",
        condition: [
          {
            on: "",
            value: "",
            operation: "ANY",
            is_selected: false,
            start_time: "04:30:00",
            end_time: "13:30:00",
          },
        ],
      };
      setCriteria(criteria1);

      return setTempValue((tempValue) => !tempValue);
    }
  }, []);

  const detailCall = (id: any) => {
    return WebService.getAPI({
      action: `chat-automation/details?id=` + id,
      body: null,
    })
      .then((res: any) => {
        //  console.log(res)
        watchvariable.name = res.info.name;
        setValue("name", watchvariable.name);
        var selectedChannels: any = [];

        if (res.info.channels != null) {
          var temp: any = [];
          var cust_Type_temp: any = [];
          var isNewUser = true;
          var isExUser = true;
          res.info.channels.map((channel: any) => {
            if (channel.customer_type === "NEWUSER" && isNewUser) {
              cust_Type_temp.push({
                label: "Message from new user",
                value: "NEWUSER",
              });
              isNewUser = false;
            } else if (channel.customer_type === "EXISTINGUSER" && isExUser) {
              cust_Type_temp.push({
                label: "Message from existing user",
                value: "EXISTINGUSER",
              });
              isExUser = false;
            }
          });
          setCustomerTypeArray(cust_Type_temp);
        }
        var criteriaObject = JSON.parse(res.info.criteria);
        setCriteria(criteriaObject);
        var i = 0;
        criteriaObject.action.map((item: any) => {
          if (item.name === "auto_reply") {
            setEditorData(item.value)
            //  setEditorErrorFlag(false)
          }
          selectedAction[i] = item.name;
          i++;
          if (item.name === "auto_reply" && params.id) {
            setShowEditButton(true);
          } else {
            setShowEditButton(false);
          }
        });
        var temp1: any = [];
        set_social_channel_selected(true);
        setIsCusSelected(true);
        setSocialChannelArray([]);
        res.info.channels.map((element: any) => {
          if (element.facebook_page) {
            var obj = {
              label: element.facebook_page.page_name,
              value: element.facebook_page.id,
              image: element.facebook_page.image,
            };
          } else {
            var obj = {
              label: element.whatsapp_phone_number.phone,
              value: element.whatsapp_phone_number.id,
              image: element.whatsapp_phone_number.image,
            };
          }

          temp1.push(obj);
        });
        console.log(temp1);
        setSocialChannelArray(temp1);
      })
      .catch((error) => { });
  };

  const saveRule = () => {
    if (customerTypeArray.length === 0) {
      setOptionErrorFlag(true);
      return;
    } else {
      setOptionErrorFlag(false);
    }

    watchvariable.criteria = criteria;
    // if (status) {
    //   watchvariable.status = "ACTIVE";
    // } else {
    //   watchvariable.status = "INACTIVE";
    // }
    watchvariable.status = "ACTIVE";
    watchvariable.customer_type = customerTypeArray;

    var social_channel: any[] = [];
    socialChannelArray.map((element: any) => {
      var obj = {
        id: element.value,
      };
      social_channel.push(obj);
    });
    watchvariable.social_channels = social_channel;
    if (!params.id) {
      WebService.addLoader("saveRule");
      WebService.postAPI({
        action: `chat-automation`,
        body: watchvariable,
      })
        .then((res: any) => {
          WebService.removeLoader("saveRule");
          toast.success(res.message);
          window.history.back();
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
          WebService.removeLoader("saveRule");
        });
    } else {
      WebService.addLoader("saveRule");
      watchvariable.id = params.id;
      WebService.putAPI({
        action: `chat-automation`,
        body: watchvariable,
      })
        .then((res: any) => {
          WebService.removeLoader("saveRule");
          toast.success(res.message);
          window.history.back();
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
          WebService.removeLoader("saveRule");
        });
    }
  };
  const handleError = (e: any) => {
    return false;
  };

  const onChangeChannels = (selectOptions: any) => {
    setSocialChannelArray([]);
    if (selectOptions.length > 0) {
      setSocialChannelArray(selectOptions);
      set_social_channel_selected(true);
    } else {
      set_social_channel_selected(false);
    }
  };

  const backToChatAutomation = () => {
    reset();
    window.history.back();
  };
  const onChangeOption = (selectOptions: any) => {
    setCustomerTypeArray([]);
    if (selectOptions.length > 0) {
      setCustomerTypeArray(selectOptions);
      unregister("customer_type");
      setIsCusSelected(true);
    } else {
      setIsCusSelected(false);
    }
  };

  useEffect(() => { }, []);
  const closeEditModal = () => {
    if (criteria.action[indexForEditor].value === "") {
      setEditorErrorFlag(true);
    } else {
      setEditorErrorFlag(false);
      setEditModal(false);
    }
  };

  const selectOptions = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      var option = [
        {
          value: "NEWUSER",
          label: "Message from new user",
        },
        {
          value: "EXISTINGUSER",
          label: "Message from existing user",
        },
      ];
      resolve(option);
    });

  const tagOptions = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      if (isTagListPending) {
        return abortController.abort();
      }
      abortController = new AbortController();
      isTagListPending = true;
      return WebService.getAPI({
        action: `support-tag/list?page=` + 1 + `&keyword=` + inputValue,
        body: null,
        signal: abortController.signal
      })
        .then((res: any) => {
          if (res.count > 0) {
            var updatedOptions = res.list.map((tag: any) => ({
              value: tag.id,
              label: HelperService.titleCase(tag.name),
              is_tag_selected: false,
            }));
            isTagListPending = false;
            resolve(updatedOptions);
          } else {
            isTagListPending = false;
            resolve([]);
          }
        })
        .catch((error) => {
          isTagListPending = false;
          resolve([]);
          return error;
        });
    });
  const memberOptions = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      if (isMemberListPending) {
        return abortController.abort();
      }
      abortController = new AbortController();
      isMemberListPending = true;
      return WebService.getAPI({
        action:
          `member/list?page=1` +
          `&user_id=` +
          localStorage.getItem("p_user_id") +
          `&app_type=` +
          localStorage.getItem("platform"),
        body: null,
        signal: abortController.signal
      })
        .then((res: any) => {
          if (res.list.length > 0) {
            var updatedOptions = res.list.map((element: any) => ({
              value: element.user?.id,
              label: HelperService.titleCase(element?.name),
              is_assigned_selected: false,
            }));
            var options: any[] = [];
            updatedOptions.map((option: any) => {
              if (option.label !== undefined) {
                options.push(option);
              }
            });
            isMemberListPending = false;
            resolve(options);
          } else {
            isMemberListPending = false;
            resolve([]);
          }
        })
        .catch((error) => {
          isMemberListPending = false;
          resolve([]);
          return error;
        });
    });
  const addNewConditionObject = () => {
    if (criteria.condition.length < 3) {
      setShowEditButton(false);
      var condition = {
        on: "",
        operation: "ANY",
        value: "",
        is_selected: false,
        start_time: "04:30:00",
        end_time: "13:30:00",
      };

      criteria.condition.push(condition);
    }
    return setTempValue((tempValue) => !tempValue);
  };

  const parseTime = (timeStr: string): Date => {
    const [hours, minutes, seconds] = timeStr?.split(":").map(Number);
    const date = new Date();
    date.setUTCHours(hours, minutes, seconds);
    return date;
  };

  const addNewActionObject = () => {
    if (criteria.action.length < 3) {
      var obj1 = {
        name: "",
        value: "",
        editor: "",
        key: "",
        is_tag_selected: false,
      };
      criteria.action.push(obj1);
    }
    return setTempValue((tempValue) => !tempValue);
  };

  const conditionOptions = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      var groupOption = [];
      var content: any[] = [];
      var obj = {
        value: "message_body",
        label: "Message Body",
      };

      content.push(obj);
      var condition = [];
      condition = allConditionOption;
      condition.push(obj);

      groupOption.push({ label: "Content", options: content });
      var ticketStatus: any[] = [];
      obj = {
        value: "ticket_is_opened",
        label: "Ticket is opened",
      };

      ticketStatus.push(obj);
      condition.push(obj);
      obj = {
        value: "ticket_is_closed",
        label: "Ticket is closed",
      };
      ticketStatus.push(obj);
      condition.push(obj);
      
      groupOption.push({ label: "Ticket status", options: ticketStatus });

      let time: any[] = [];
      let timeObj = {
        value: "message_timing",
        label: "Message timing",
      };

      time.push(timeObj);
      condition.push(timeObj);

      groupOption.push({ label: "Time", options: time });
      
      setAllConditionOptions(condition);

      resolve(groupOption);
    });
  const setCondtion = (value: any, index: any, type: any) => {
    if (type === "action_operation") {
      criteria.action_operation = value;
    } else if (type === "on") {
      criteria.condition[index].on = value.value;
      criteria.condition[index].on_label = value.label;
      criteria.condition[index].is_selected = true;
      // setIs_option_selected(true)
      unregister("option_" + index);
    } else if (type === "value") {
      criteria.condition[index].value = value;
    } else if (type === "operation") {
      criteria.condition[index].operation = value;
      unregister("operation_" + index);
    } else if (type === "start_time") {
      criteria.condition[index].start_time = moment.utc(value).format("HH:mm:ss");
    } else if (type === "end_time") {
      criteria.condition[index].end_time = moment.utc(value).format("HH:mm:ss");
    }
    return setTempValue((tempValue) => !tempValue);
  };

  const saveEditorData = () => {
    if (criteria.action[indexForEditor].value === "") {
      setEditorErrorFlag(true);
    } else {
      setEditorErrorFlag(false);
      closeEditModal();
    }

    return setTempValue((tempValue) => !tempValue);
  };
  const sendData = (data: any) => {
    setShow(false);
  };
  const promiseOptions = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      if (isSupportSourceListPending) {
        return abortController.abort();
      }
      abortController = new AbortController();
      isSupportSourceListPending = true;
      return WebService.getAPI({
        action: `support/source-list?keyword=` + inputValue,
        body: null,
        signal: abortController.signal
      })
        .then((res: any) => {
          var groupedOptions = [];
          if (res.list.length === 0 && res.whatsapp.length === 0 && inputValue === "") {
            setShow(true);
          } else {
            setShow(false);
            if (res.list.length > 0) {
              var instagramOption: any[] = [];
              var twitterOption: any[] = [];
              var facebookOption: any[] = [];
              var linkdnOption: any[] = [];
              var gmailOption: any[] = [];
              res.list.forEach((channel: any) => {
                var obj = {
                  value: channel.id,
                  // label: channel.page_name,

                  label: (
                    <>
                      <img
                        src={channel.image}
                        alt="logo"
                        width={15}
                        className="me-1"
                        onError={(error: any) => {
                          error.target.src = WebmaxyIcon;
                        }}
                      />
                      {channel.page_name}
                    </>
                  ),
                };

                var sobj = {
                  label: channel.page_name,
                  value: channel.id,
                };
                var tempS = socialChannels;
                tempS.push(sobj);
                setSocialChannels(tempS);

                if (channel.social_type === "INSTAGRAM") {
                  instagramOption.push(obj);
                } else if (channel.social_type === "TWITTER") {
                  twitterOption.push(obj);
                } else if (channel.social_type === "FACEBOOK") {
                  facebookOption.push(obj);
                } else if (channel.social_type === "LINKEDIN") {
                  linkdnOption.push(obj);
                } else if (channel.social_type === "GMAIL") {
                  gmailOption.push(obj);
                }
              });

              if (instagramOption.length > 0) {
                groupedOptions.push({
                  label: (
                    <>
                      <img
                        src={InstaLogo}
                        alt="logo"
                        width={15}
                        className="me-1"
                        onError={(error: any) => {
                          error.target.src = WebmaxyIcon;
                        }}
                      />
                      {"Instagram"}
                    </>
                  ),
                  options: instagramOption,
                });
              }
              if (twitterOption.length > 0) {
                groupedOptions.push({
                  label: (
                    <>
                      <img
                        src={TwitterLogo}
                        alt="logo"
                        width={15}
                        className="me-1"
                        onError={(error: any) => {
                          error.target.src = WebmaxyIcon;
                        }}
                      />
                      {"Twitter"}
                    </>
                  ),
                  options: twitterOption,
                });
              }
              if (facebookOption.length > 0) {
                groupedOptions.push({
                  label: (
                    <>
                      <img
                        src={FBLogo}
                        alt="logo"
                        width={15}
                        className="me-1"
                        onError={(error: any) => {
                          error.target.src = WebmaxyIcon;
                        }}
                      />
                      {"Facebook"}
                    </>
                  ),
                  options: facebookOption,
                });
              }
              if (linkdnOption.length > 0) {
                groupedOptions.push({
                  label: (
                    <>
                      <img
                        src={LinkdnLogo}
                        alt="logo"
                        width={15}
                        className="me-1"
                        onError={(error: any) => {
                          error.target.src = WebmaxyIcon;
                        }}
                      />
                      {"Linkedin"}
                    </>
                  ),
                  options: linkdnOption,
                });
              }
              if (gmailOption.length > 0) {
                groupedOptions.push({
                  label: (
                    <>
                      <img
                        src={gmailLogo}
                        alt="logo"
                        width={15}
                        className="me-1"
                        onError={(error: any) => {
                          error.target.src = WebmaxyIcon;
                        }}
                      />
                      {"Gmail"}
                    </>
                  ),
                  options: gmailOption,
                });
              }
            }
            if (res.whatsapp.length > 0) {
              var whatsappOption: any[] = [];
              res.whatsapp.forEach((channel: any) => {
                var obj = {
                  value: channel.id,
                  label: (
                    <>
                      <img
                        src={
                          // "https://performance.webmaxy.co/assets/images/noImage.png"
                          whatsAppLogo
                        }
                        alt="logo"
                        width={15}
                        className="me-1"
                      />
                      {channel.phone}
                    </>
                  ),
                };
                var sobj = {
                  label: channel.phone,
                  value: channel.id,
                };
                var tempS = socialChannels;
                tempS.push(sobj);
                setSocialChannels(tempS);
                whatsappOption.push(obj);
              });
              if (whatsappOption.length > 0) {
                groupedOptions.push({
                  label: (
                    <>
                      <img
                        src={whatsAppLogo}
                        alt="logo"
                        width={15}
                        className="me-1"
                        onError={(error: any) => {
                          error.target.src = WebmaxyIcon;
                        }}
                      />
                      {"WhatsApp"}
                    </>
                  ),
                  options: whatsappOption,
                });
              }
            }
          }
          isSupportSourceListPending = false;
          resolve(groupedOptions);
        })
        .catch((error) => {
          isSupportSourceListPending = false;
          resolve([]);
          return error;
        });
    });
  const deleteCondition = (index: any) => {
    criteria.condition.splice(index, 1);
    return setTempValue((tempValue) => !tempValue);
  };

  const deleteActionOption = (index: any) => {
    criteria.action.splice(index, 1);
    return setTempValue((tempValue) => !tempValue);
  };
  const setActionChange = (value: any, index: any, type: any) => {
    criteria.action[index].name = value;
    if (type === "tag_name") {
      criteria.action[index].value = value;
    }

    return setTempValue((tempValue) => !tempValue);
  };

  return (
    <>
      <div className="app-body add-rule-page">
        <section className="page-breadcums">
          <Container fluid>
            <div className="">
              <Row className="align-items-center">
                <Col md={6}>
                  <Breadcrumb>
                    {
                      localStorage.getItem("platform") !== "WHATSAPP-COMMERCE" ?
                        <Breadcrumb.Item><Link to={"/kpi-dashboard"}>{t('Common.Home')}</Link>{" "}</Breadcrumb.Item>
                        : <Breadcrumb.Item><Link to={"/helpdesk-dashboard"}>{t('Common.Home')}</Link>{" "}</Breadcrumb.Item>
                    }
                    {/* <Breadcrumb.Item onClick={() => navigate("/kpi-dashboard")}>
                      {t("Common.Home")}
                    </Breadcrumb.Item> */}
                    <Breadcrumb.Item>
                      {t("ChatAutomationList.Help_Desk")}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                      onClick={() => navigate("/chat-automation")}
                    >
                      {t("ChatAutomationList.Chat_Automation")}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                      {params.id
                        ? t("ChatAutomationList.Edit_Rule")
                        : t("ChatAutomationList.Add_Rule")}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </Col>
                <Col md={6} className="text-end"></Col>
              </Row>
            </div>
          </Container>
        </section>
        <Container fluid>
          <Container fluid>
            <div className="table-filtr-wrap mb-3">
              <Row className="align-items-center">
                <Col md={6}>
                  <div className="table-filtr-wrap">
                    <h2 className="table-heading">
                      {params.id
                        ? t("ChatAutomationList.Edit_Rule")
                        : t("ChatAutomationList.Add_Rule")}
                    </h2>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>

          <Form
            className="wmx-form-style"
            name="Verify"
            id="Verify"
            onSubmit={handleSubmit(saveRule, handleError)}
          >
            <Card className="p-4 mb-3">
              <div className="wmx-form-style">
                <Row className=" row-cols-lg-3 row-cols-1 align-items-end">
                  <Col>
                    <FloatingLabel
                      label={t("ChatAutomationList.Rule_Name")}
                      className=" "
                    >
                      <Form.Control
                        {...register("name", { required: true })}
                        type="text"
                        id="name"
                        placeholder="Rule Name"
                        maxLength={100}
                        className={"name" in errors ? "has-error" : "no-error"}
                        onChange={(e) => {
                          setValue("name", e.target.value);
                          watchvariable.name = e.target.value;
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col>
                    <span className="font-14">{t("Common.WHEN")}</span>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      {...(!is_social_channel_selected
                        ? { ...register("social_channel", { required: true }) }
                        : "")}
                      className={
                        "social_channel" in errors
                          ? "has-async-error"
                          : "no-error"
                      }
                      onChange={onChangeChannels}
                      loadOptions={promiseOptions}
                      placeholder={t("Common.Select_Inbox")}
                      value={socialChannelArray}
                      isMulti
                    />
                  </Col>

                  <Col>
                    <AsyncSelect
                      {...(!is_cus_selected
                        ? { ...register("customer_type", { required: true }) }
                        : "")}
                      className={
                        "customer_type" in errors
                          ? "has-async-error"
                          : "no-error"
                      }
                      cacheOptions
                      defaultOptions
                      onChange={onChangeOption}
                      loadOptions={selectOptions}
                      placeholder={t("Common.Select_Option")}
                      isMulti
                      value={customerTypeArray}
                    />
                  </Col>
                </Row>
              </div>
            </Card>
            <Card className="p-4 mb-3">
              <span className="gate-name font-10 ms-4 ps-3">
                of the condition(s)
              </span>
              <div className="segment-main-wrap mb-4">
                <div className="seg-gate">
                  <div className="plus-icon">
                    <BiPlus
                      size={40}
                      className="icon cursor-pointer"
                      onClick={addNewConditionObject}
                    />
                  </div>

                  <span className="gate-name font-12">
                    <span className="font-medium text-uppercase font-14">
                      IF
                    </span>
                  </span>
                </div>
                <div className="w-100">
                  {criteria?.condition.map((element: any, index: any) => {
                    return (
                      <div className="d-flex ">
                        <div className="conector"></div>
                        <div className="w-100">
                          <div className="control-card w-100 parent align-items-center">
                            <Form.Select
                              id="action_operation"
                              defaultValue={element.action_operation}
                              onChange={(event: any) => {
                                setCondtion(
                                  event.target.value,
                                  index,
                                  "action_operation"
                                );
                              }}
                            >
                              <option value="ANY">{t("Common.Any")}</option>
                              <option value="ALL">{t("Common.All")}</option>
                            </Form.Select>

                            <AsyncSelect
                              cacheOptions
                              {...(!element.is_selected
                                ? {
                                  ...register("option_" + index, {
                                    required: true,
                                  }),
                                }
                                : "")}
                              className={
                                "option_" + index in errors
                                  ? "has-async-error"
                                  : "no-error"
                              }
                              defaultOptions
                              defaultValue={{
                                label: element.on_label
                                  ? element.on_label
                                  : "Choose Option",
                                value: element.on ? element.on : "",
                              }}
                              loadOptions={conditionOptions}
                              placeholder={t("Common.Choose_Option")}
                              onChange={(event: any) => {
                                setCondtion(event, index, "on");
                              }}
                            />
                            {element.on === "message_body" ? (
                              <>
                                <Form.Select
                                  defaultValue={element.value}
                                  {...register("operation_" + index, {
                                    required: true,
                                  })}
                                  className={
                                    "operation_" + index in errors
                                      ? "has-error"
                                      : "no-error"
                                  }
                                  onChange={(event: any) => {
                                    setCondtion(
                                      event.target.value,
                                      index,
                                      "operation"
                                    );
                                  }}
                                >
                                  <option value="contain_any">
                                    Contains any of
                                  </option>
                                  <option value="contain_all">
                                    Contains all of
                                  </option>
                                  <option value="contain_none">
                                    Contains none of
                                  </option>
                                </Form.Select>
                                <input
                                  type="text"
                                  {...register("value_" + index, {
                                    required: true,
                                  })}
                                  className={
                                    "value_" + index in errors
                                      ? "has-error form-control pe-3 w-50"
                                      : "no-error form-control pe-3 w-50"
                                  }
                                  placeholder="Enter comma separated values here without spaces"
                                  defaultValue={element.value}
                                  onChange={(event: any) => {
                                    setCondtion(
                                      event.target.value,
                                      index,
                                      "value"
                                    );
                                  }}
                                />
                              </>
                            ) : (
                              ""
                            )}

                            {element.on === "message_timing" ? (
                              <>
                                <Form.Select
                                  defaultValue={element.operation}
                                  {...register("operation_" + index, {
                                    required: true,
                                  })}
                                  className={
                                    "operation_" + index in errors
                                      ? "has-error"
                                      : "no-error"
                                  }
                                  onChange={(event: any) => {
                                    setCondtion(
                                      event.target.value,
                                      index,
                                      "operation"
                                    );
                                  }}
                                >
                                  <option value="">
                                    Select
                                  </option>
                                  <option value="include">
                                    Include
                                  </option>
                                  <option value="exclude">
                                    Exclude
                                  </option>
                                </Form.Select>

                                <span className="font-14">From :</span>
                                <ReactDatePicker
                                  className="form-control"
                                  selected={parseTime(element.start_time)}
                                  onChange={(date: Date) => {
                                    setCondtion(
                                      date,
                                      index,
                                      "start_time"
                                    );
                                  }}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={30}
                                  timeCaption="Time"
                                  dateFormat="hh:mm a"
                                />

                                <span className="font-14">To :</span>
                                <ReactDatePicker
                                  className="form-control"
                                  selected={parseTime(element.end_time)}
                                  onChange={(date: Date) => {
                                    setCondtion(
                                      date,
                                      index,
                                      "end_time"
                                    );
                                  }}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={30}
                                  timeCaption="Time"
                                  dateFormat="hh:mm a"
                                />
                              </>
                            ) : (
                              ""
                            )}

                            {index !== 0 ? (
                              <button className="btn btn-delete">
                                <PiTrashSimpleBold
                                  size={18}
                                  className="icon"
                                  onClick={() => deleteCondition(index)}
                                />
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="segment-main-wrap then-part">
                <span className="gate-name font-10 ms-4 ps-3">
                  Perform this action
                </span>
              </div>

              <div className="segment-main-wrap mb-4 then-part">
                <div className="seg-gate">
                  <div className="plus-icon">
                    <BiPlus
                      size={16}
                      className="icon"
                      onClick={addNewActionObject}
                    />
                  </div>
                  <span className="gate-name">
                    <span className="font-medium text-uppercase font-12">
                      THEN
                    </span>{" "}
                  </span>
                </div>
                <div className="w-100">
                  <div className="d-flex ">
                    <div className="conector"></div>
                    <div className="w-100">
                      {criteria?.action.map((option: any, ind: any) => {
                        return (
                          <div className="control-card w-100 parent">
                            <Form.Select
                              className={
                                "action_" + ind in errors
                                  ? "has-error"
                                  : "no-error"
                              }
                              {...register("action_" + ind, {
                                required: true,
                              })}
                              defaultValue={option.name}
                              onChange={(e) => {

                                option.name = e.target.value;
                                var localCriteria = { ...criteria };
                                localCriteria.action[ind].name = e.target.value;
                                setCriteria(localCriteria);
                                unregister("action_" + ind);
                                option.value = "";
                                option.action_label = "";
                                var selectedAct = selectedAction;
                                selectedAct[ind] = e.target.value;
                                setSelectedAction(selectedAct);
                                if (e.target.value === "auto_reply") {
                                  setEditModal(true)
                                }
                              }}
                            >
                              <option value="">
                                {t("Common.Select_Action")}
                              </option>
                              <option
                                value="add_tag"
                                disabled={selectedAction.includes("add_tag")}
                              >
                                Add Tag
                              </option>
                              <option
                                value="assign_to"
                                disabled={selectedAction.includes("assign_to")}
                              >
                                Assign to
                              </option>
                              <option
                                value="unassign"
                                disabled={selectedAction.includes("unassign")}
                              >
                                Unassign
                              </option>
                              <option
                                value="close_ticket"
                                disabled={selectedAction.includes(
                                  "close_ticket"
                                )}
                              >
                                Close ticket
                              </option>

                              <option
                                value="optout"
                                disabled={selectedAction.includes("optout")}
                              >
                                Optout
                              </option>

                              <option
                                value="auto_reply"
                                disabled={selectedAction.includes("auto_reply")}
                              >
                                Auto reply
                              </option>
                            </Form.Select>
                            {option.name === "auto_reply" ? (
                              <Button
                                className="loader-btn btn-outline-blue"
                                onClick={() => {
                                  setIndexForEditor(ind);
                                  setEditModal(true);
                                  criteria.action[ind].value = option.value;
                                  setEditorData(option.value);
                                }}
                              >
                                <MdModeEditOutline size={16} />{" "}
                                {t("Common.Edit")}
                              </Button>
                            ) : (
                              ""
                            )}
                            {option.name === "add_tag" ? (
                              <AsyncSelect
                                {...(!option.is_tag_selected
                                  ? {
                                    ...register("tag_" + ind, {
                                      required: true,
                                    }),
                                  }
                                  : "")}
                                className={
                                  "tag_" + ind in errors
                                    ? "has-async-error"
                                    : "no-error"
                                }
                                cacheOptions
                                defaultOptions
                                loadOptions={tagOptions}
                                defaultValue={{
                                  label: option.action_label
                                    ? option.action_label
                                    : "Choose Option",
                                  value: option.value ? option.value : "",
                                }}
                                placeholder={t("Common.Choose_Option")}
                                onChange={(e: any) => {
                                  var obj = {
                                    label: "Select Option",
                                    value: "",
                                  };
                                  setValue("assign_" + ind, obj);
                                  option.value = e.value;
                                  option.action_label = e.label;
                                  option.is_tag_selected = true;
                                  var localCriteria = { ...criteria };
                                  localCriteria.action[ind].value = e.value;
                                  localCriteria.action[ind].action_label =
                                    e.label;
                                  localCriteria.action[ind].is_tag_selected =
                                    false;
                                  setCriteria(localCriteria);
                                  localCriteria.action[ind].is_tag_selected =
                                    true;
                                  unregister("tag_" + ind);
                                }}
                              />
                            ) : (
                              ""
                            )}
                            {option.name === "assign_to" ? (
                              <AsyncSelect
                                cacheOptions
                                defaultOptions
                                loadOptions={memberOptions}
                                {...(!option.is_assigned_selected
                                  ? {
                                    ...register("assign_" + ind, {
                                      required: true,
                                    }),
                                  }
                                  : "")}
                                className={
                                  "assign_" + ind in errors
                                    ? "has-async-error"
                                    : "no-error"
                                }
                                defaultValue={{
                                  label: option.action_label
                                    ? option.action_label
                                    : "Choose Option",
                                  value: option.value ? option.value : "",
                                }}
                                placeholder="Choose Member"
                                onChange={(e: any) => {
                                  var obj = {
                                    label: "Select Option",
                                    value: "",
                                  };
                                  setValue("tag_" + ind, obj);
                                  option.value = e.value;
                                  option.action_label = e.label;
                                  var localCriteria = { ...criteria };
                                  localCriteria.action[ind].value = e.value;
                                  localCriteria.action[ind].action_label =
                                    e.label;
                                  localCriteria.action[
                                    ind
                                  ].is_assigned_selected = true;
                                  setCriteria(localCriteria);
                                  unregister("assign_" + ind);
                                }}
                              />
                            ) : (
                              ""
                            )}
                            {ind !== 0 ? (
                              <button className="btn btn-delete">
                                <PiTrashSimpleBold
                                  size={18}
                                  className="icon"
                                  onClick={() => deleteActionOption(ind)}
                                />
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="offcanvas-footer d-flex justify-content-end">
                <Button
                  className="btn btn-wmx-gray me-2"
                  onClick={backToChatAutomation}
                >
                  <MdClose size={18} className="icon" /> {t("Common.Cancel")}
                </Button>
                <Button
                  className="loader-btn btn-wmx"
                  type="submit"
                  id="saveRule"
                >
                  <BsSave size={16} /> {t("Common.Save")}
                </Button>
              </div>
            </Card>
          </Form>
        </Container>
      </div>

      {/* Edit  */}
      <Offcanvas
        show={showEditModal}
        onHide={closeEditModal}
        placement="end"
        className="righSide-modal size-md"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {t("ChatAutomationList.Auto_Reply")}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="wmx-normal-form">
          <div style={{ textAlign: "right" }}>
            {editorData?.length > 0 ? editorData?.length - 7 : 0}/{maxLength}{" "}
          </div>
          {/* <div  className="ck-editor__editable_inline"> */}
          <CKEditor
            editor={Editor}
            config={editorConfiguration}
            data={editorData}
            onChange={(event: any, editor: any) => {
              const data = editor.getData();
              if (data.length <= 1507) {
                setEditorData(data);
              } else {
                editor.setData(editorData);
              }

              // setEditorData(data);
              criteria.action[indexForEditor].value = data;
            }}
          />
          {/* </div> */}
          {editorErrorFlag ? (
            <Label
              title={t("ChatAutomationList.Enter_Data_Err_Msg")}
              modeError={true}
            />
          ) : (
            ""
          )}
          {/* <p className="font-400 font-14 mt-3">
            <span className="font-500">{t("Common.Note")}:</span>{" "}
            {t("ChatAutomationList.Insert_Variable_Instruction")}
          </p> */}
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <Button className="btn btn-wmx-gray" onClick={closeEditModal}>
            <MdClose size={18} className="icon" /> {t("Common.Cancel")}
          </Button>
          <Button
            className="loader-btn btn-wmx"
            id="edit"
            onClick={saveEditorData}
          >
            {" "}
            {t("Common.Save")}
          </Button>
        </div>
      </Offcanvas>
      {show ? (
        <Offcanvas
          show={show}
          onHide={() => {
            setShow(false);
          }}
          placement="end"
          className="righSide-modal size-md"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{t("Common.Connect_Channels")}</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="wmx-normal-form">
            <SocialChannels
              sendData={(data: any) => {
                sendData(data);
              }}
            />
          </Offcanvas.Body>
          {/* <div className='offcanvas-footer d-flex justify-content-end'>
                        <Button variant="secondary" onClick={handleAddClose}><MdClose size={18} className='icon' /> Close </Button>
                    </div> */}
        </Offcanvas>
      ) : (
        ""
      )}
      <AccessControl
        page_type={"HELP_DESK"}
        count={0}
        type={"ACCESS"}
        code={"CHAT_AUTOMATION"}
        access_type={"VIEW"}
        onComplete={(access: any) => setAccess(access)}
      />
    </>
  );
};
export default AddRule;
